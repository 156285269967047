import React, { useState } from "react";
import { Box, Typography } from "@mui/material";
import CustomHeader from "../../common/CustomHeader";
import { connect } from "react-redux";
import { fetchWaiters, createWaiter, updateWaiter } from "../../redux/floors";
import { withStyles } from "@mui/styles";
import Waiter from "../../assets/Waiter.png";
import ButtonWithIcon from "../../common/ButtonWithIcon";
import { ReactComponent as UserCirclePlusIcon } from "../../assets/Icons/UserCirclePlus.svg";
import { ReactComponent as AddWaiter } from "../../assets/Icons/AddWaiter.svg";
import { ReactComponent as DeleteBin } from "../../assets/Icons/DeleteBin.svg";
import CustomAddWaiterDialog from "../../common/CustomAddWaiterDialog";
import CustomWaiterTable from "../../common/CustomWaiterTable";
import { useEffect } from "react";
import CustomDialogWithButton from "../../common/CustomDialogWIthButton";

const tableHeads = [
  { label: "S.No.", key: "index" },
  { label: "Waiter Name", key: "waiter_name" },
  { label: "Phone no.", key: "phone_number" },
  { label: "Password", key: "password" },

  "",
];
const WaiterDashboard = (props) => {
  useEffect(() => {
    props.fetchWaiters();
  }, []);

  const handleWaiterChanges = async (obj) => {
    let response = await props.createWaiter(obj);
    if (response) {
      setAddWaiterDialog(false);
      setDialog(true);
    } else {
      alert("Something went wrong");
    }
  };

  const [addWaiterDialog, setAddWaiterDialog] = useState(false);
  const [dialog, setDialog] = useState(false);
  const [deleteDialog, setDeleteDialog] = useState(false);
  const [editWaiter, setEditWaiter] = useState({});

  return (
    <React.Fragment>
      <Box sx={{ padding: 6, width: "calc(100% - 360px)", height: "100%" }}>
        <Box
          display={"flex"}
          alignItems={"center"}
          justifyContent={"space-between"}
        >
          <CustomHeader headerText={"Waiters"} />
          {props.waiters.length ? (
            <ButtonWithIcon
              onClick={() => setAddWaiterDialog(true)}
              icon={
                <UserCirclePlusIcon
                  style={{ marginRight: "4px", width: "24px" }}
                />
              }
              label={"Add Waiter"}
            />
          ) : null}
        </Box>

        {props.waiters.length ? (
          <Box mt={8}>
            <CustomWaiterTable
              tableHeads={tableHeads}
              tableItems={props.waiters}
              key={Date.now()}
              updateWaiter={props.updateWaiter}
              onEdit={(waiterObject) => {
                setEditWaiter(waiterObject);
                setDeleteDialog(true);
              }}
            />
          </Box>
        ) : (
          <Box
            style={{
              width: "100%",
              height: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
              gap: "16px",
            }}
          >
            <img src={Waiter} style={{ width: "350px" }} />

            <ButtonWithIcon
              onClick={() => setAddWaiterDialog(true)}
              icon={
                <UserCirclePlusIcon
                  style={{ marginRight: "4px", width: "24px" }}
                />
              }
              label={"Add Waiter"}
            />
          </Box>
        )}
        <CustomAddWaiterDialog
          key={Date.now()}
          open={addWaiterDialog}
          handleClose={() => {
            setAddWaiterDialog(false);
          }}
          onSave={(obj) => {
            handleWaiterChanges(obj);
          }}
          editWaiter={{}}
        />
      </Box>
      <CustomDialogWithButton
        open={dialog}
        handleClose={() => {
          setDialog(false);
        }}
        iconImage={<AddWaiter strokeWidth={0} width={32} height={32} />}
        message={
          "The new waiter can now log in to the mobile app with the created credentials."
        }
        title={"New Waiter Created"}
      />
      <CustomDialogWithButton
        open={deleteDialog}
        handleClose={() => setDeleteDialog(false)}
        iconImage={<DeleteBin strokeWidth={0} width={32} height={32} />}
        message={"Are you certain about deleting the waiter's profile?"}
        title={""}
        onDelete={async () => {
          const response = await props.updateWaiter({
            ...editWaiter,
            isDeleted: true,
          });
          if (response) {
            setEditWaiter({});
            setDeleteDialog(false);
          } else {
            alert("Something went wrong");
          }
        }}
        isDelete={true}
      />
    </React.Fragment>
  );
};
const styles = {};
const connectedWaiterDashboard = connect(
  (state) => ({
    waiters: state.floors.waiters,
  }),
  {
    fetchWaiters,
    createWaiter,
    updateWaiter,
  }
)(WaiterDashboard);

export default withStyles(styles)(connectedWaiterDashboard);
