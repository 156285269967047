import React from "react";
import styled from "styled-components";
import { OverlapGroupWrapper } from "../OverlapGroupWrapper/OverlapGroupWrapper";
import { Box } from "@mui/material";
import { FrameWrapper } from "../FrameWrapper/FrameWrapper";
import { Div } from "../Div/Div";
import { Frame } from "../Frame/Frame";
import Logo from "../../../../../../assets/Logo.png";

const StyledSectionComponentNode = styled.div`
  & .rectangle {
    width: 355px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 32px 0px 16px;
  }

  & .text-wrapper-12 {
    font-family: "Manrope";
    font-size: 14px;
    font-weight: 700;
    text-align: center;
  }

  & .text-wrapper-13 {
    font-family: "Manrope";
    font-size: 12px;
    font-weight: 500;
    text-align: center;
    width: 282px;
  }

  & .logo-type {
    height: 58px;
    object-fit: cover;
    width: 58px;
  }
`;

export const SectionComponentNode = ({
  theme,
  primaryColor,
  selectedFile,
  brandName,
  description,
}) => {
  return (
    <>
      <StyledSectionComponentNode>
        <div
          className="rectangle"
          style={{
            backgroundColor: `${primaryColor}15`,
          }}
        >
          <img
            className="logo-type"
            alt="Logo type"
            src={selectedFile || Logo}
          />
          <div
            className="text-wrapper-12"
            style={{
              marginTop: "8px",
              color: primaryColor,
            }}
          >
            Welcome to {brandName}
          </div>
          <p
            className="text-wrapper-13"
            style={{
              color: theme === "Dark" ? "#ffffff" : "#000000",
            }}
          >
            {description}
          </p>
        </div>
      </StyledSectionComponentNode>
      <Box
        display={"flex"}
        alignItems={"center"}
        gap={"8px"}
        padding={"0px 16px"}
        marginTop={"16px"}
      >
        <OverlapGroupWrapper theme={theme} />
        <div
          style={{
            backgroundColor: theme === "Dark" ? "#191919" : "#F5F5F5",
            borderRadius: "7.28px",
            height: "44px",
            width: "44px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <img
            style={{
              width: "22px",
            }}
            alt="Clock counter"
            src="https://c.animaapp.com/qmMdcQ0L/img/clockcounterclockwise.svg"
          />
        </div>
      </Box>
      <Box
        mt={2}
        display={"flex"}
        alignItems={"center"}
        gap={"8px"}
        padding={"0px 16px"}
      >
        <Frame theme={theme} />
        <Div theme={theme} />
        <FrameWrapper theme={theme} />
      </Box>
    </>
  );
};
