import { Box, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import BackArrow from "../../common/BackArrow";
import ButtonWithIcon from "../../common/ButtonWithIcon";
import CustomTable from "../../common/CustomTable";
import { useParams } from "react-router-dom";
import { withStyles } from "@mui/styles";
import CustomDialog from "../../common/CustomDialog";
import Tableoccupied from "../../assets/Tableoccupied.png";
import { ReactComponent as RadioButton } from "../../assets/Icons/RadioButton.svg";
import jsonData from "../../data.json";
import moment from "moment";
import axios from "axios";
import { URL } from "../../config/config";
import CustomHeader from "../../common/CustomHeader";

function getFormattedDate(date) {
  const d = new Date(date);
  return date ? moment(d).format("DD-MM-yyyy") : "";
}

function getFormattedTime(date) {
  const d = new Date(date);
  return date ? moment(d).format("hh:mm A") : "";
}
const Header = ({ tableNo, handleOpenDialog, transactionData }) => {
  return (
    <Box
      display={"flex"}
      alignItems={"center"}
      justifyContent={"space-between"}
    >
      <Box display={"flex"} alignItems={"center"} gap={"16px"}>
        <BackArrow />
        <CustomHeader headerText={`Table ${tableNo}`} />
      </Box>
      <Box
        display={"flex"}
        alignItems={"center"}
        gap={"8px"}
        style={{
          background: "#E1DFDC",
          padding: "12px 24px",
          borderRadius: "8px",
        }}
      >
        <Typography>Billed on:</Typography>
        <Typography margin={"0px 8px"}>
          {getFormattedDate(transactionData.updatedAt)}
        </Typography>
        <Typography>{getFormattedTime(transactionData.updatedAt)}</Typography>
      </Box>
    </Box>
  );
};
const FinalBilling = (props) => {
  const [dialogOpen, setDialogOpen] = useState(false);
  const [transactionData, setTransactionData] = useState(false);

  const { transactionId } = useParams();

  useEffect(() => {
    axios
      .post(URL + "clients/get-all-transaction", {
        transactionId,
      })
      .then((res) => {
        const { data } = res.data;
        setTransactionData(data[0]);
      })
      .catch((err) => {
        console.error(err);
      });
  }, [transactionId]);

  const handleOpenDialog = () => {
    setDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
  };

  const allOrderedFood = (orderedFoods) => {
    let foodOrders = {};
    let calculatedTotalAmount = 0;
    (orderedFoods || []).forEach((value) => {
      value.food_ordered.map((value, index, array) => {
        if (foodOrders[value._id]) {
          foodOrders[value._id].quantity += value.quantity;
          foodOrders[value._id].total_price += value.quantity * value.price;
        } else {
          if (value._id) {
            foodOrders[value._id] = {
              food_name: value.food_name,
              food_type: value.food_type,
              price: value.price,
              quantity: value.quantity,
              total_price: value.price * value.quantity,
            };
          }
        }
      });
    });
    Object.values(foodOrders).forEach((value, index, array) => {
      calculatedTotalAmount += value.total_price;
    });
    return {
      foodOrders,
      totalAmount: calculatedTotalAmount,
    };
  };

  return (
    <React.Fragment>
      <Box sx={{ padding: 6, width: "calc(100% - 360px)", height: "100%" }}>
        <Header
          tableNo={transactionData?.tables?.table_name}
          handleOpenDialog={() => handleOpenDialog()}
          transactionData={transactionData}
        />
        {true ? (
          <Box
            mt={6}
            style={{
              height: "80%",
              overflow: "auto",
              display: "flex",
              flexDirection: "column",
              gap: "16px",
            }}
          >
            <Box
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "16px",
              }}
            >
              <CustomTable
                tableData={[
                  ...Object.values(
                    allOrderedFood(transactionData.orderedFood).foodOrders
                  ),
                ]}
                allDone={false}
                finalBill={true}
                isFinal={true}
              />
            </Box>
          </Box>
        ) : (
          <Box
            style={{
              width: "100%",
              height: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
              gap: "16px",
            }}
          >
            <img src={Tableoccupied} style={{ width: "350px" }} />
            <ButtonWithIcon
              icon={
                <RadioButton style={{ marginRight: "4px", width: "24px" }} />
              }
              label={"Set status to occupied"}
            />
          </Box>
        )}
      </Box>
      <CustomDialog open={dialogOpen} handleClose={handleCloseDialog} />
    </React.Fragment>
  );
};

const useStyles = (theme) => ({
  root: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "flex-end",
    height: "100%",
  },
});

export default withStyles(useStyles)(FinalBilling);
