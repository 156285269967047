import React from "react";
import { Line } from "react-chartjs-2";
import "chart.js/auto";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
  graphContainer: {
    padding: "20px",
    background: "transparent", // This sets the background color similar to the image
    borderRadius: "8px", // This makes the edges rounded
    width: "100%",
    height: "100%",
  },
});

const data = {
  labels: ["", "", "", "", "", "", ""],
  datasets: [
    {
      label: "",
      fill: true,
      lineTension: 0,
      backgroundColor: "#E1DFDC",
      borderColor: "#1D2020",
      borderCapStyle: "butt",
      borderDash: [],
      borderDashOffset: 0.0,
      borderJoinStyle: "miter",
      pointBorderColor: "transparent", // This sets the border color similar to the image
      pointBackgroundColor: "#fff",
      pointBorderWidth: 0,
      pointHoverRadius: 5,
      pointHoverBackgroundColor: "transparent",
      pointHoverBorderColor: "transparent",
      pointHoverBorderWidth: 0,
      pointRadius: 3,
      pointHitRadius: 10,
      data: [65, 59, 80, 81, 56, 55, 40],
    },
  ],
};

const options = {
  maintainAspectRatio: false, // This will maintain the aspect ratio of your canvas and prevent it from stretching
  scales: {
    y: {
      beginAtZero: true, // This ensures that the y-axis starts at 0
    },
  },
};

const CustomGraph = () => {
  const classes = useStyles();

  return (
    <div className={classes.graphContainer}>
      <Line data={data} options={options} />
    </div>
  );
};

export default CustomGraph;
