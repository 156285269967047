import { Box, Typography } from "@mui/material";
import React from "react";

const Header = () => {
  return (
    <Box>
      <Typography variant="h6" fontWeight="500">
        Party
      </Typography>
    </Box>
  );
};

const FoodParty = () => {
  return (
    <Box sx={{ padding: 6, width: "calc(100% - 360px)" }}>
      <Header />
    </Box>
  );
};

export default FoodParty;
