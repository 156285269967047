import axios from "axios";
import { URL } from "../config/config";

const initialState = {
  isLoading: false,
  floors: [],
  waiters: [],
  orderingFloors: [],
  error: null,
  clientData: {},
};

const START_FETCH = "Floors/START_FETCH";
const FETCH_SUCCESS = "Floors/FETCH_SUCCESS";
const FETCH_SUCCESS_ORDERING = "Floors/FETCH_SUCCESS_ORDERING";
const FETCH_FAILURE = "Floors/FETCH_FAILURE";
const RESET_ERROR = "Floors/RESET_ERROR";
const RESET_FETCH = "Floors/RESET_FETCH";
const ADD_FLOOR = "Floors/ADD_FLOOR";
const ADD_WAITER = "Floors/ADD_WAITER";
const FETCH_CLIENT_PROFILE = "Floors/FETCH_CLIENT_PROFILE";

const fetchSuccess = (floors) => ({
  type: FETCH_SUCCESS,
  payload: floors,
});

const fetchSuccessOrdering = (floors) => ({
  type: FETCH_SUCCESS_ORDERING,
  payload: floors,
});

const addFloor = (floor) => ({
  type: ADD_FLOOR,
  payload: floor,
});

const addWaiter = (waiter) => ({
  type: ADD_WAITER,
  payload: waiter,
});

const fetchClientProfile = (clientData) => ({
  type: FETCH_CLIENT_PROFILE,
  payload: clientData,
});

export const fetchFloors =
  (isFromOrdering = false) =>
  (dispatch) => {
    axios
      .post(URL + "clients/get-all-floors", { isFromOrdering })
      .then((res) => {
        const { data } = res.data;
        if (isFromOrdering) {
          dispatch(fetchSuccessOrdering(data));
        } else {
          dispatch(fetchSuccess(data));
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

export const createFloor = (floorObject) => async (dispatch) => {
  try {
    const res = await axios.post(
      URL + "clients/create-floor-table",
      floorObject
    );
    const { data } = res.data;
    if (data.length) {
      dispatch(addFloor(data[0]));
    }
    return true;
  } catch (err) {
    console.error(err);
    return false;
  }
};

export const updateFloor = (floorObject) => async (dispatch) => {
  try {
    await axios.post(URL + "clients/update-floor-table", {
      updateObject: floorObject,
    });
    dispatch(fetchFloors());
    return true;
  } catch (err) {
    console.error(err);
    return false;
  }
};

export const deleteFloor = (floorObject) => async (dispatch) => {
  try {
    await axios.post(URL + "clients/delete-floor-table", {
      floorId: floorObject._id,
    });
    dispatch(fetchFloors());
    return true;
  } catch (err) {
    console.error(err);
    return false;
  }
};

export const updateTableStatus =
  (floorId, tableId, statusClient) => async (dispatch, getState) => {
    try {
      const { orderingFloors } = getState().floors;

      const response = await axios.post(URL + "clients/update-table-status", {
        tableId,
        statusClient,
      });
      const floor = orderingFloors.find((flr) => flr._id === floorId);

      if (floor) {
        const table = (floor.tables || []).find((tbl) => tbl._id == tableId);
        table["status_client"] = statusClient;
        dispatch(fetchSuccessOrdering(orderingFloors));
      }
      return true;
    } catch (err) {
      console.error(err);
      return false;
    }
  };

// export const sendBill =
//   ({ userNumber, tableId, currentBookingId, totalAmount }) =>
//   (dispatch, getState) => {
//     const { floors } = getState().floors;

//     axios
//       .post(URL + "clients/send-bill", {
//         userNumber,
//         tableId,
//         currentBookingId,
//         totalAmount,
//       })
//       .then((res) => {
//         const floor = floors.find((flr) => flr._id === floorId);
//         const table = floor.tables.find((tbl) => tbl._id == tableId);
//         table["status_client"] = statusClient;
//         dispatch(fetchSuccess(floors));
//       })
//       .catch((err) => {
//         console.error(err);
//       });
//   };

export const fetchWaiters = () => async (dispatch) => {
  try {
    const res = await axios.post(URL + "clients/get-all-waiter");
    const { data } = res.data;
    dispatch(addWaiter(data));
    return true;
  } catch (err) {
    console.error(err);
    return false;
  }
};

export const createWaiter = (waiterObject) => async (dispatch) => {
  try {
    await axios.post(URL + "clients/create-waiter", waiterObject);
    dispatch(fetchWaiters());
    return true;
  } catch (err) {
    console.error(err);
    return false;
  }
};

export const updateWaiter = (waiterObject) => async (dispatch) => {
  try {
    await axios.post(URL + "clients/update-waiter", waiterObject);
    dispatch(fetchWaiters());
    return true;
  } catch (err) {
    console.error(err);
    return false;
  }
};

export const getClientProfile = () => async (dispatch) => {
  try {
    await axios
      .post(URL + "clients/client-details")
      .then((value) => {
        const data = value.data.data;
        dispatch(
          fetchClientProfile({
            place_name: data.place_name,
            address: data.address,
            city: data.city,
            state: data.state,
            postal_code: data.postal_code,
            upi_id: data.upi_id,
            cgst: data.cgst,
            sgst: data.sgst,
            gst_no: data.gst_no,
            isEdited: data.isEdited,
          })
        );
      })
      .catch((value) => {
        console.log({ value });
      });
    return true;
  } catch (err) {
    console.error(err);
    return false;
  }
};

export default function FloorsFetchReducer(
  state = initialState,
  { type, payload }
) {
  switch (type) {
    case START_FETCH:
      return {
        ...state,
        isLoading: true,
      };
    case FETCH_SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: null,
        floors: [...payload],
      };
    case FETCH_SUCCESS_ORDERING:
      return {
        ...state,
        isLoading: false,
        error: null,
        orderingFloors: [...payload],
      };

    case FETCH_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: payload,
      };
    case RESET_ERROR:
      return {
        ...state,
        error: null,
      };
    case ADD_FLOOR:
      return {
        ...state,
        floors: [...state.floors, payload],
      };

    case ADD_WAITER:
      return {
        ...state,
        waiters: [...payload],
      };
    case FETCH_CLIENT_PROFILE:
      return {
        ...state,
        clientData: payload,
      };

    case RESET_FETCH:
      return { ...initialState };
    default:
      return state;
  }
}
