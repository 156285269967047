import { Box, Grid, Typography } from "@mui/material";
import React from "react";

const CustomHeader = ({ headerText, headerComponent }) => {
  return (
    <Box>
      <Typography variant="h6" fontWeight="bold">
        {headerText}
      </Typography>
      {headerComponent && (
        <Grid display="flex" gap={4} mt={2}>
          {headerComponent}
        </Grid>
      )}
    </Box>
  );
};

export default CustomHeader;
