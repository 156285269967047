import React from "react";
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@mui/material";
import QuantitySelector from "./QuantitySelector";

const StyledTableCell = ({ align, children }) => (
  <TableCell align={align} style={{ padding: 8 }}>
    {children}
  </TableCell>
);

const CustomDialogTable = ({
  addedItems,
  handleIncrement,
  handleDecrement,
}) => {
  return (
    <TableContainer
      style={{
        width: "90%",
        height: "490px",
        marginTop: "16px",
        background: "#FAF8F4",
        borderRadius: "8px",
      }}
    >
      <Table>
        <TableHead
          style={{
            background: "#E1DFDC",
            position: "sticky",
            top: 0,
            zIndex: 1,
          }}
        >
          <TableRow>
            <StyledTableCell align="center">S.No.</StyledTableCell>
            <StyledTableCell align="center">Items</StyledTableCell>
            <StyledTableCell align="center">Quantity</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {addedItems.map((row, index) => {
            return (
              <TableRow key={row.id}>
                <StyledTableCell align="center">{index + 1}.</StyledTableCell>
                <StyledTableCell align="center">
                  {row.food_name}
                </StyledTableCell>
                <StyledTableCell align="center" >
                  <QuantitySelector
                    value={row.quantity}
                    onIncrement={() => handleIncrement(row._id)}
                    onDecrement={() => handleDecrement(row._id)}
                  />
                </StyledTableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default CustomDialogTable;
