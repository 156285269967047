import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import CustomButton from "./CustomButton";
import { withStyles } from "@mui/styles";
import { Box, Typography } from "@mui/material";
import { Check } from "@mui/icons-material";
import moment from "moment/moment";
import { connect } from "react-redux";

function getFormattedDate(date) {
  const d = new Date(date);
  return date ? moment(d).format("hh:mm A") : "";
}

function CustomTable(props) {
  const classes = props.classes;
  const { cgst, sgst } = props.clientData;

  const calculateTotalAmount = () => {
    let calculatedTotalAmount = 0;
    if (props.tableData) {
      props.tableData.map((value) => {
        calculatedTotalAmount += value.total_price;
      });
    }
    if (props.setTotalAmount) {
      props.setTotalAmount(
        calculatedTotalAmount +
          (calculatedTotalAmount / 100) * +(cgst || 0) +
          (calculatedTotalAmount / 100) * +(sgst || 0)
      );
    }
    return calculatedTotalAmount;
  };

  return (
    <TableContainer
      key={Date.now()}
      component={Paper}
      style={{
        borderRadius: "8px",
        boxShadow: "none",
        background: "#FAF8F4",
      }}
    >
      <Box style={{ overflowY: "auto" }}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead
            style={{
              background: "#E1DFDC",
              position: "sticky",
              top: 0,
              zIndex: 1,
            }}
          >
            <TableRow>
              <TableCell className={classes.tableSNoCell}>S.No.</TableCell>
              <TableCell>Food Item</TableCell>
              <TableCell className={classes.tableCell}>Quantity</TableCell>
              <TableCell className={classes.tableCell}>Price</TableCell>
              {props.isFinal || props.finalBill ? null : (
                <>
                  <TableCell className={classes.tableCell}>Time</TableCell>
                  <TableCell className={classes.tableCell}>
                    {props.allDone ? (
                      ""
                    ) : (
                      <CustomButton
                        label={"All Completed"}
                        onClick={() => props.updateStatus(props.foodOrderId)}
                      />
                    )}
                  </TableCell>
                </>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {props.tableData.reverse().map((row, index) => {
              return (
                <TableRow
                  key={row._id}
                  sx={{
                    opacity: row.isDone ? 0.6 : 1,
                    "&:last-child td, &:last-child th": { border: 0 },
                  }}
                >
                  <TableCell className={classes.tableSNoCell}>
                    {index > 8 ? "" : 0}
                    {index + 1}.
                  </TableCell>
                  <TableCell>
                    <Box display={"flex"} gap={"8px"}>
                      {row.food_type ? (
                        <Box
                          style={{
                            width: "16px",
                            height: "16px",
                            border: "2px solid #2D7D4D",
                            borderRadius: "5px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <Box
                            style={{
                              width: "8px",
                              height: "8px",
                              background: "#2D7D4D",
                              borderRadius: "50%",
                            }}
                          />
                        </Box>
                      ) : (
                        <Box
                          style={{
                            width: "16px",
                            height: "16px",
                          }}
                        />
                      )}
                      {row.food_name}
                    </Box>
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    x {row.quantity}
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    ₹ {row.quantity * row.price}
                  </TableCell>
                  {props.isFinal || props.finalBill ? null : (
                    <>
                      <TableCell className={classes.tableCell}>
                        {getFormattedDate(props.createdAt)}
                      </TableCell>
                      <TableCell className={classes.tableCell}>
                        {row.status || props.allDone ? (
                          <Check style={{ margin: "auto", width: "100%" }} />
                        ) : (
                          <CustomButton
                            label={"Done"}
                            onClick={() =>
                              props.updateStatus(props.foodOrderId, row._id)
                            }
                          />
                        )}
                      </TableCell>
                    </>
                  )}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </Box>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        {props.isFinal && (
          <>
            <TableRow className={classes.bottomRow}>
              <TableCell>
                <Box display={"flex"} gap={"8px"}>
                  SGST
                </Box>
              </TableCell>
              <TableCell className={classes.tableCell}></TableCell>
              <TableCell className={classes.tableCell}>{sgst || 0}%</TableCell>
              <TableCell className={classes.tableCell}>
                ₹
                {Number((calculateTotalAmount() / 100) * +(sgst || 0)).toFixed(
                  2
                )}
              </TableCell>
            </TableRow>
            <TableRow className={classes.bottomRow}>
              <TableCell>
                <Box display={"flex"} gap={"8px"}>
                  CGST
                </Box>
              </TableCell>
              <TableCell className={classes.tableCell}></TableCell>
              <TableCell className={classes.tableCell}>{cgst || 0}%</TableCell>
              <TableCell className={classes.tableCell}>
                ₹
                {Number((calculateTotalAmount() / 100) * +(cgst || 0)).toFixed(
                  2
                )}
              </TableCell>
            </TableRow>
            <TableRow className={classes.bottomRow}>
              <TableCell>
                <Box display={"flex"} gap={"8px"}>
                  <Typography fontSize={"14px"} fontWeight="bold">
                    Total
                  </Typography>
                </Box>
              </TableCell>
              <TableCell className={classes.tableCell}></TableCell>
              <TableCell className={classes.tableCell}></TableCell>
              <TableCell className={classes.tableCell}>
                <Typography fontSize={"14px"} fontWeight="bold">
                  ₹
                  {Number(
                    calculateTotalAmount() +
                      (calculateTotalAmount() / 100) * +(sgst || 0) +
                      (calculateTotalAmount() / 100) * +(cgst || 0)
                  ).toFixed(2)}
                </Typography>
              </TableCell>
            </TableRow>
          </>
        )}
      </Table>
    </TableContainer>
  );
}

const useStyles = (theme) => ({
  tableSNoCell: {
    textAlign: "left",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    width: "10px",
  },
  tableCell: {
    textAlign: "left",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    width: "130px",
  },
  tableNameCell: {
    display: "flex",
    alignItems: "center",
    justifyContent: "start",
    gap: "8px",
  },
  bottomRow: {
    backgroundColor: "#E1DFDC",
  },
});

const connectedCustomTable = connect(
  (state) => ({
    floors: state.floors.orderingFloors,
    clientData: state.floors.clientData,
  }),
  {}
)(CustomTable);

export default withStyles(useStyles)(connectedCustomTable);
