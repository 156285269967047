import { Box, FormControl, MenuItem, Select, Typography } from "@mui/material";
import { withStyles } from "@mui/styles";
import React, { useEffect, useState } from "react";
import CustomBillingTable from "../../common/CustomBillingTable";
import CustomizedDividers from "../../common/CustomToggle";
import axios from "axios";
import { URL } from "../../config/config";
import CustomHeader from "../../common/CustomHeader";
import { connect } from "react-redux";
import { fetchFloors, getClientProfile } from "../../redux/floors";

const DropDown = (list = [], selectedFloor, onChange) => {
  return (
    <FormControl
      style={{
        marginLeft: "auto",
        minWidth: 120,
        border: "2px solid #1D2020",
        borderRadius: "8px",
        outline: "none",
      }}
    >
      <Select
        placeholder="Select Floor"
        label="Select Floor"
        value={selectedFloor}
        onChange={(value) => {
          onChange(value.target.value);
        }}
      >
        <MenuItem value={"all"}>All Floor</MenuItem>
        {list.map((value) => {
          return <MenuItem value={value._id}>{value.floor_name}</MenuItem>;
        })}
      </Select>
    </FormControl>
  );
};

const FoodBilling = (props) => {
  const [billingData, setBillingData] = useState([]);
  const [selectedFloor, setSelectedFloor] = useState("all");

  useEffect(() => {
    props.fetchFloors();
    props.getClientProfile();
    fetchTransaction("", "all");
  }, []);

  const fetchTransaction = (floorId = "", duration = "") => {
    axios
      .post(URL + "clients/get-all-transaction", {
        floorId: floorId == "all" ? "" : floorId,
        duration,
      })
      .then((res) => {
        const { data } = res.data;
        setBillingData(data);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  return (
    <Box sx={{ padding: 6, width: "calc(100% - 360px)" }}>
      <CustomHeader headerText={"Billing"} />
      <Box
        my={4}
        style={{
          display: "flex",
          width: "100%",
          justifyContent: "space-between",
        }}
      >
        <CustomizedDividers
          onChange={(value) => {
            fetchTransaction(selectedFloor, value);
          }}
        />{" "}
        {DropDown(props.floors, selectedFloor, (value) => {
          fetchTransaction(value, "all");
          setSelectedFloor(value);
        })}
      </Box>
      <CustomBillingTable tableData={billingData} />
    </Box>
  );
};

const useStyles = (theme) => ({});
const connectedFoodBilling = connect(
  (state) => ({
    floors: state.floors.floors,
  }),
  {
    fetchFloors,
    getClientProfile,
  }
)(FoodBilling);

export default withStyles(useStyles)(connectedFoodBilling);
