import { Box } from "@mui/material";
import React, { useState, useEffect } from "react";

const Clock = () => {
  const [currentTime, setCurrentTime] = useState(new Date());

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentTime(new Date());
    }, 1000); // Update every second

    // Clear the interval when the component is unmounted
    return () => clearInterval(intervalId);
  }, []);

  const daysOfWeek = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  const dayOfWeek = daysOfWeek[currentTime.getDay()];
  const month = months[currentTime.getMonth()];
  const dayOfMonth = currentTime.getDate();
  const hours = currentTime.getHours();
  const minutes = currentTime.getMinutes();
  const ampm = hours >= 12 ? "PM" : "AM";

  const formattedTime = `${dayOfMonth} ${month}, ${
    hours % 12 > 9 ? hours % 12 : `0${hours % 12}`
  }:${minutes.toString().padStart(2, "0")} ${ampm}`;

  return (
    <Box
      my={4}
      style={{
        border: "1px solid white",
        borderRadius: "8px",
        padding: "16px 24px",
      }}
    >
      {formattedTime}
    </Box>
  );
};

export default Clock;
