import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./app/App";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import store from "./redux/store";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import setAuthToken from "./utils/setAuthentication";
import { jwtDecode } from "jwt-decode";

const theme = createTheme({
  typography: {
    fontFamily: "Manrope, sans-serif",
  },
});

// authentication logic
const login = async () => {
  try {
    if (localStorage.getItem("jwtToken")) {
      setAuthToken(`Bearer ${localStorage.getItem("jwtToken")}`);
      const decoded = jwtDecode(localStorage.getItem("jwtToken"));
      const currentTime = Date.now() / 1000;
      if (decoded.exp < currentTime) {
        localStorage.removeItem("jwtToken");
        // await store.dispatch({ type: "Login/SIGN_OUT_SUCCESS" });
      } else {
        // await store.dispatch({ type: "Login/LOGIN_SUCCESS", payload: decoded });
      }
    }
  } catch (error) {
    console.error({ message: "invalid JWT token", jwtError: error });
    // await store.dispatch({ type: "Login/SIGN_OUT_SUCCESS" });
  }
};

const root = ReactDOM.createRoot(document.getElementById("root"));

login().then(() => {
  root.render(
    <React.StrictMode>
      <Provider store={store}>
        <App />
      </Provider>
    </React.StrictMode>,
    document.getElementById("root")
  );
});
// root.render(
//   <React.StrictMode>
//     <ThemeProvider theme={theme}>
//       <Provider store={store}>
//         <App />
//       </Provider>
//     </ThemeProvider>
//   </React.StrictMode>
// );

reportWebVitals();
