import { TextField } from "@mui/material";
import React from "react";
import { ReactComponent as Plus } from "../assets/Icons/Plus.svg";
import { ReactComponent as Minus } from "../assets/Icons/Minus.svg";

const QuantitySelector = ({ value, onIncrement, onDecrement }) => {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        width: "100%",
        gap: "5px",
        background: "rgb(230, 230, 230)",
        height: "56px",
        borderRadius: "4px",
        justifyContent: "space-evenly",
      }}
    >
      <div
        style={{
          width: "33%",
          height: "100%",
          background: "#CCCCCC",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          borderRadius: "4px",
        }}
        onClick={() => onDecrement(value - 1)}
      >
        <Minus style={{ cursor: "pointer" }} />
      </div>
      <TextField
        size="small"
        value={value}
        style={{ flex: 1, border: "none" }}
        InputProps={{
          disableUnderline: true,
        }}
        inputProps={{
          style: {
            textAlign: "center",
            width: "10px",
            border: "none",
            flex: 1,
          },
          readOnly: true,
        }}
        variant="standard"
      />
      <div
        style={{
          width: "33%",
          height: "100%",
          background: "#CCCCCC",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          borderRadius: "4px",
        }}
        onClick={() => onIncrement(value + 1)}
      >
        <Plus style={{ cursor: "pointer" }} />
      </div>
    </div>
  );
};

export default QuantitySelector;
