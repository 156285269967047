import React, { useState } from "react";
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Box,
} from "@mui/material";
import { ReactComponent as PencilSimpleLine } from "../assets/Icons/PencilSimpleLine.svg";
import { ReactComponent as Trash } from "../assets/Icons/Trash.svg";

import CustomSwitch from "./CustomSwitch";

const StyledTableCell = ({ align, children }) => (
  <TableCell align={align} style={{ padding: 16 }}>
    {children}
  </TableCell>
);

const CustomWaiterTable = ({
  tableHeads,
  tableItems,
  updateWaiter,
  key,
  onEdit,
}) => {
  const [update, setUpdate] = useState(false);

  return (
    <TableContainer
      key={key}
      style={{
        width: "100%",
        height: "400px",
        marginTop: "16px",
        background: "#FAF8F4",
        borderRadius: "8px",
      }}
    >
      <Table>
        <TableHead
          style={{
            background: "#E1DFDC",
            position: "sticky",
            top: 0,
            zIndex: 1,
          }}
        >
          <TableRow>
            {tableHeads.map((value, index, array) => {
              return (
                <StyledTableCell align="start">
                  {value?.label || ""}
                </StyledTableCell>
              );
            })}
          </TableRow>
        </TableHead>
        <TableBody>
          {tableItems.map((value, index) => {
            return (
              <TableRow>
                <StyledTableCell align="start" style={{ display: "flex" }}>
                  {index + 1}
                </StyledTableCell>
                <StyledTableCell align="start">
                  {value.waiter_name}
                </StyledTableCell>
                <StyledTableCell align="start">
                  {value.phone_number}
                </StyledTableCell>
                <StyledTableCell align="start">
                  {value.password}
                </StyledTableCell>
                <StyledTableCell align="start">
                  <Box
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "16px",
                    }}
                  >
                    <CustomSwitch
                      status={value.status}
                      onClick={(status) => {
                        updateWaiter({ ...value, status });
                      }}
                    />
                    <Trash
                      style={{ cursor: "pointer" }}
                      onClick={() => onEdit(value)}
                    />
                  </Box>
                </StyledTableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default CustomWaiterTable;
