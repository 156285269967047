import React from "react";
import { FoodType } from "../../../../components/FoodType";
import styled from "styled-components";
import { ArrowBackIos, ArrowForwardIos } from "@mui/icons-material";

const StyledOverlap = styled.div`
  width: 357px;
  position: absolute;
  bottom: 0px;
  & .group {
    height: 44px;
    width: 111px;
  }

  & .overlap-group-2 {
    background-color: #dbdd261a;
    border: 0.91px solid;
    border-color: #dbdd26;
    border-radius: 7.28px;
    height: 44px;
    position: relative;
    width: 109px;
  }

  & .text-wrapper {
    color: #dbdd26;
    font-family: "Manrope";
    font-size: 16.4px;
    font-weight: 700;
    height: 22px;
    letter-spacing: 0;
    line-height: normal;
    width: 52px;
  }

  & .text-wrapper-2 {
    color: #ffffff;
    font-family: "Manrope";
    font-size: 16.4px;
    font-weight: 500;
    height: 22px;
    letter-spacing: 0;
    line-height: normal;
    width: 182px;
  }

  & .design-component-instance-node {
    border-radius: 3.18px !important;
    height: 6px !important;
    width: 6px !important;
  }

  & .pasta-tossed-in {
    color: #999999;
    font-family: "Manrope";
    font-size: 12.7px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: normal;
    width: 182px;
  }

  & .group-2 {
    height: 73px;
    width: 357px;
  }

  & .overlap-group-3 {
    background-color: #dbdd26;
    height: 73px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px 16px;
  }

  & .text-wrapper-3 {
    color: #000000;
    font-family: "Manrope";
    font-size: 16.4px;
    font-weight: 700;
    height: 22px;
    letter-spacing: 0;
    line-height: normal;
    width: 146px;
  }

  & .group-3 {
    height: 22px;
    width: 120px;
    display: flex;
    align-items: center;
  }

  & .text-wrapper-4 {
    color: #000000;
    font-family: "Manrope";
    font-size: 16.4px;
    font-weight: 700;
    height: 22px;
    letter-spacing: 0;
    line-height: normal;
    width: 100%;
  }

  & .caret-right {
    height: 15px;
    width: 15px;
  }
`;

export const Overlap = ({ primaryColor, theme }) => {
  return (
    <StyledOverlap>
      <div
        className="group-2"
        style={{
          backgroundColor: primaryColor,
        }}
      >
        <div
          className="overlap-group-3"
          style={{
            backgroundColor: primaryColor,
            color: theme === "Dark" ? "black" : "white",
          }}
        >
          <div
            className="text-wrapper-3"
            style={{
              color: theme === "Dark" ? "black" : "white",
            }}
          >
            1 item added
          </div>
          <div className="group-3">
            <div
              className="text-wrapper-4"
              style={{
                display: "flex",
                color: theme === "Dark" ? "black" : "white",
              }}
            >
              View Cart
              <ArrowForwardIos />
            </div>
          </div>
        </div>
      </div>
    </StyledOverlap>
  );
};
