import { Box, TextField, Typography } from "@mui/material";
import React, { useState, useEffect } from "react";
import CustomHeader from "../../common/CustomHeader";
import axios from "axios";
import { URL } from "../../config/config";
import ButtonWithIcon from "../../common/ButtonWithIcon";

const CustomTextFieldWithLabel = ({
  label,
  placeholder,
  value,
  onChange,
  isEdited,
}) => {
  return (
    <Box display={"flex"} alignItems={"center"}>
      <Typography variant="p" width={"120px"}>
        {label}
      </Typography>
      <TextField
        disabled={isEdited}
        variant="standard"
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        InputProps={{
          disableUnderline: true,
          style: {
            borderRadius: "8px",
            background: "#E6E6E6",
            width: "480px",
            padding: "8px 24px",
          },
        }}
      />
    </Box>
  );
};

const PublicProfile = () => {
  const [profile, setProfile] = useState({
    name: "",
    address: "",
    city: "",
    state: "",
    postal_code: "",
    upi_id: "",
    cgst: "",
    sgst: "",
    gst_no: "",
    isEdited: true,
  });

  useEffect(() => {
    getClientProfile();
  }, []);

  const getClientProfile = async () => {
    await axios
      .post(URL + "clients/client-details")
      .then((value) => {
        const data = value.data.data;
        setProfile({
          place_name: data.place_name,
          address: data.address,
          city: data.city,
          state: data.state,
          postal_code: data.postal_code,
          upi_id: data.upi_id,
          cgst: data.cgst,
          sgst: data.sgst,
          gst_no: data.gst_no,
          isEdited: data.isEdited,
        });
      })
      .catch((value) => {
        // alert("Something went wrong");
      });
  };

  const onSubmit = async () => {
    await axios
      .post(URL + "clients/update-client-profile", {
        ...profile,
        isEdited: true,
      })
      .then((value) => {
        getClientProfile();
      })
      .catch((value) => {
        alert("Something went wrong");
      });
  };

  return (
    <Box sx={{ padding: 6, width: "calc(100% - 360px)" }}>
      <Box display={"flex"} flexDirection={"column"} gap={"8px"}>
        <Box
          mb={4}
          display={"flex"}
          alignItems={"center"}
          justifyContent={"space-between"}
        >
          <CustomHeader headerText={"Profile"} />
          {profile.isEdited ? null : (
            <ButtonWithIcon label={"Save"} onClick={() => onSubmit()} />
          )}
        </Box>
        <CustomTextFieldWithLabel
          label="Name"
          placeholder="Enter name"
          value={profile.place_name}
          onChange={(e) =>
            setProfile({ ...profile, place_name: e.target.value })
          }
          isEdited={profile.isEdited}
        />
        <CustomTextFieldWithLabel
          label="Address"
          placeholder="Enter address"
          value={profile.address}
          onChange={(e) => setProfile({ ...profile, address: e.target.value })}
          isEdited={profile.isEdited}
        />
        <CustomTextFieldWithLabel
          label="City"
          placeholder="Enter city"
          value={profile.city}
          onChange={(e) => setProfile({ ...profile, city: e.target.value })}
          isEdited={profile.isEdited}
        />
        <CustomTextFieldWithLabel
          label="State"
          placeholder="Enter state"
          value={profile.state}
          onChange={(e) => setProfile({ ...profile, state: e.target.value })}
          isEdited={profile.isEdited}
        />
        <CustomTextFieldWithLabel
          label="Postal Code"
          placeholder="Enter postal code"
          value={profile.postal_code}
          onChange={(e) =>
            setProfile({ ...profile, postal_code: e.target.value })
          }
          isEdited={profile.isEdited}
        />
      </Box>

      <Box mt={8} display={"flex"} flexDirection={"column"} gap={"8px"}>
        <Box mb={4}>
          <CustomHeader headerText={"Billing"} />
        </Box>
        <CustomTextFieldWithLabel
          label="UPI ID"
          placeholder="Enter upi id"
          value={profile.upi_id}
          onChange={(e) => setProfile({ ...profile, upi_id: e.target.value })}
          isEdited={profile.isEdited}
        />
        <CustomTextFieldWithLabel
          label="CGST"
          placeholder="Enter cgst"
          value={profile.cgst}
          onChange={(e) => setProfile({ ...profile, cgst: e.target.value })}
          isEdited={profile.isEdited}
        />
        <CustomTextFieldWithLabel
          label="SGST"
          placeholder="Enter sgst"
          value={profile.sgst}
          onChange={(e) => setProfile({ ...profile, sgst: e.target.value })}
          isEdited={profile.isEdited}
        />
        <CustomTextFieldWithLabel
          label="GSTIN"
          placeholder="Enter gst no."
          value={profile.gst_no}
          onChange={(e) => setProfile({ ...profile, gst_no: e.target.value })}
          isEdited={profile.isEdited}
        />
      </Box>
    </Box>
  );
};

export default PublicProfile;
