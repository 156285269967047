import React, { useEffect } from "react";
import { Box, Grid, Paper, Typography, Stack } from "@mui/material";
import { useNavigate, withRouter } from "react-router-dom";
import CustomHeader from "../../common/CustomHeader";
import { connect } from "react-redux";
import { fetchFloors, getClientProfile } from "../../redux/floors";
import { withStyles } from "@mui/styles";
import Twofactorauthentication from "../../assets/Twofactorauthentication.png";
import { ReactComponent as PlusSquare } from "../../assets/Icons/PlusSquare.svg";
import ButtonWithIcon from "../../common/ButtonWithIcon";
import { socket } from "../../utils/socket";

// Define table status colors and status descriptions
const tableStatusColors = {
  empty: "#CCCCCC",
  occupied: "#DBA927",
  ordering: "#DBDD26",
  billing: "#26B1DD",
};

const statuses = [
  { label: "Empty", color: "#CCCCCC" },
  { label: "Occupied", color: "#DBA927" },
  { label: "Ordering", color: "#DBDD26" },
  { label: "Billing", color: "#26B1DD" },
];

function getOrderFoodFalseCount(orderedFood) {
  let count = 0;
  orderedFood.map((orderFood) => {
    return orderFood.food_ordered.map((food) => {
      if (!food.status) {
        count += food.quantity;
      }
    });
  });

  return count;
}

// Table component representing each individual table
const Table = ({ place, selectTable }) => {
  return (
    <Grid item xs={6} sm={4} md={3} lg={2} xl={1.5} mb={4}>
      <Paper
        elevation={0}
        sx={{
          bgcolor: "#FAF8F4",
          padding: 2,
          display: "flex",
          flexDirection: "column",
          position: "relative",
          borderRadius: "8px",
          border: "1px solid #cccccc",
        }}
      >
        <Typography
          variant="h6"
          fontWeight={"bold"}
          style={{ textTransform: "capitalize" }}
        >
          {place.floor_name}
        </Typography>
        <Grid
          display={"flex"}
          sx={{
            flexWrap: "wrap",
          }}
          gap={4}
          mt={4}
        >
          {(place?.tables || []).map((table, i) => (
            <Box
              key={i}
              sx={{
                position: "relative",
                width: "85px",
                height: "85px",
                borderRadius: "8px",
                backgroundColor:
                  tableStatusColors[(table?.status_client || "").toLowerCase()],
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                cursor: "pointer",
              }}
              onClick={() => {
                selectTable(table._id);
              }}
            >
              <Typography fontFamily={"Manrope"} variant="h4" fontWeight={500}>
                {table.table_name.substring(0, 4)}
              </Typography>

              {table?.status_client === "ordering" &&
              getOrderFoodFalseCount(table.orderedFood) ? (
                <Box
                  sx={{
                    position: "absolute",
                    top: -5,
                    right: -5,
                    bgcolor: "black",
                    borderRadius: "50%",
                    width: 24,
                    height: 24,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    padding: "2px",
                  }}
                >
                  <Typography sx={{ fontSize: "15px" }} color="#DBDD26">
                    {getOrderFoodFalseCount(table.orderedFood)}
                  </Typography>
                </Box>
              ) : null}
            </Box>
          ))}
        </Grid>
      </Paper>
    </Grid>
  );
};
const FoodOrdersDashboard = (props) => {
  const navigate = useNavigate();

  useEffect(() => {
    props.fetchFloors(true);
    props.getClientProfile();
    socket.on("booking_created", (data) => {
      props.fetchFloors(true);
    });
    socket.on("food_ordered", (data) => {
      props.fetchFloors(true);
    });
    socket.on("table_status_changed", (data) => {
      props.fetchFloors(true);
    });
    socket.on("order_completed", (data) => {
      props.fetchFloors(true);
    });
    socket.on("bill_sent", (data) => {
      props.fetchFloors(true);
    });
  }, []);

  const selectTable = (tableId) => {
    navigate("/tables/table/" + tableId);
  };

  return (
    <Box
      sx={{
        padding: 6,
        width: "calc(100% - 360px)",
        height: "auto",
        overflowY: "auto",
      }}
    >
      <CustomHeader
        headerText={"Tables"}
        headerComponent={
          props.floors.length
            ? statuses.map((status, i) => {
                return (
                  <Box display={"flex"} alignItems="center" gap={2} key={i}>
                    <Box
                      width={20}
                      height={20}
                      borderRadius={1}
                      sx={{ backgroundColor: status.color }}
                    />
                    <Typography variant="p">{status.label}</Typography>
                  </Box>
                );
              })
            : null
        }
      />
      {props.floors.length ? (
        <Box mt={8}>
          {props.floors.map((place, i) => {
            return (
              <Table
                key={i}
                place={place}
                selectTable={selectTable}
                updateTableStatus={props.updateTableStatus}
              />
            );
          })}
        </Box>
      ) : (
        <Box
          style={{
            width: "100%",
            height: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
            gap: "16px",
          }}
        >
          <img src={Twofactorauthentication} style={{ width: "350px" }} />
          <ButtonWithIcon
            icon={<PlusSquare style={{ marginRight: "4px", width: "24px" }} />}
            label={"Create Floor & Add Tables"}
            onClick={() =>
              navigate("/floor-tables", {
                state: { addFloor: true },
              })
            }
          />
        </Box>
      )}
    </Box>
  );
};
const styles = {};
const connectedFoodOrdersDashboard = connect(
  (state) => ({
    floors: state.floors.orderingFloors,
  }),
  {
    fetchFloors,
    getClientProfile,
  }
)(FoodOrdersDashboard);

export default withStyles(styles)(connectedFoodOrdersDashboard);
