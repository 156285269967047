import { Button, CircularProgress } from "@mui/material";
import React from "react";

const ButtonWithIcon = ({
  label,
  icon,
  onClick,
  isDisabled = true,
  styling,
  isLoading = false,
}) => {
  return (
    <Button
      disableRipple
      sx={{
        background: !isDisabled ? "#CCCCCC" : "#DBDD26",
        color: !isDisabled ? "#717171" : "black",
        textTransform: "capitalize",
        padding: "8px",
        cursor: "pointer",
        "&:hover": {
          backgroundColor: !isDisabled ? "#CCCCCC" : "#DBDD26",
        },
        ...styling,
      }}
      onClick={() => onClick()}
    >
      {isLoading ? (
        <CircularProgress
          size={24}
          style={{ marginRight: "4px", color: "#717171" }}
        />
      ) : (
        icon
      )}

      {label}
    </Button>
  );
};

export default ButtonWithIcon;
