import { Box, TextField, Typography } from "@mui/material";
import React, { useState, useEffect } from "react";
import CustomHeader from "../../common/CustomHeader";
import { Palette } from "color-thief-react";
import { Check } from "@mui/icons-material";
import Logo from "../../assets/Logo.png";
import ThemePreview from "../../common/MenuPreview/screens/ThemePreview/ThemePreview";
import ButtonWithIcon from "../../common/ButtonWithIcon";
import axios from "axios";
import { URL } from "../../config/config";
import CustomDialogWithButtons from "../../common/CustomDialogWIthButton";
import { ReactComponent as Mobile } from "../../assets/Icons/Mobile.svg";

const theme = ["Dark", "Light"];
const MenuBranding = () => {
  const [selectedFile, setSelectedFile] = useState(null);

  const [activeTheme, setActiveTheme] = useState(1);
  const [description, setDescription] = useState("");
  const [brandName, setBrandName] = useState("");
  const [activePrimaryColor, setActivePrimaryColor] = useState(0);
  const [primaryColors, setPrimaryColors] = useState([]);
  const [updateDialog, setUpdateDialog] = useState(false);
  const [isImageUpdated, setIsImageUpdated] = useState(false);

  useEffect(() => {
    getTheme();
  }, []);

  const openFileUploader = () => {
    const input = document.getElementById("file_uploader_library");
    if (input) {
      input.click();
    }
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const fileReader = new FileReader();
      fileReader.onload = () => {
        setSelectedFile(fileReader.result);
        setIsImageUpdated(true);
        setActivePrimaryColor(0);
        // setActiveTheme(1);
      };
      fileReader.readAsDataURL(file);
    }
  };

  const updateTheme = () => {
    axios
      .post(URL + "clients/update-client-theme", {
        description,
        image: selectedFile,
        brand_name: brandName,
        primaryColor: primaryColors[activePrimaryColor],
        isImageUpdated,
      })
      .then((res) => {
        setUpdateDialog(true);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const getTheme = async () => {
    await axios
      .post(URL + "clients/fetch-client-theme")
      .then((res) => {
        setSelectedFile(res.data.data.logo);
        setDescription(res.data.data.description);
        setBrandName(res.data.data.brand_name);
        setPrimaryColors([res.data.data.primary_color]);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  return (
    <Box sx={{ padding: 6, width: "calc(100% - 360px)" }}>
      <Box
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <CustomHeader headerText={"Menu Branding"} />
        <ButtonWithIcon onClick={() => updateTheme()} label={"Save"} />
      </Box>
      <Box display={"flex"} mt={8}>
        <Box width={"50%"}>
          <Box>
            <Typography variant="p">Upload Your Logo</Typography>
            <Box
              my={1}
              style={{
                border: "1px solid grey",
                height: "135px",
                width: "135px",
                borderRadius: "8px",
                overflow: "hidden",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                cursor: "pointer",
              }}
              onClick={() => openFileUploader()}
            >
              {selectedFile ? (
                <img
                  src={selectedFile}
                  alt=""
                  style={{
                    height: "75%",
                    width: "75%",
                    objectFit: "contain",
                  }}
                />
              ) : (
                "Click to upload"
              )}

              <input
                id="file_uploader_library"
                type="file"
                // accept={this.state.fileType}
                hidden
                onChange={handleFileChange}
              />
            </Box>
          </Box>
          {/* <Box mt={2}>
            <Typography fontFamily={'Manrope'} variant="p">Select Theme</Typography>
            <div style={{ display: "flex", gap: "8px" }}>
              {["Dark", "Light"].map((value, index, array) => {
                return (
                  <Box
                    my={1}
                    style={{
                      border: "1px solid #D2D2D2",
                      height: "55px",
                      width: "75px",
                      borderRadius: "8px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      cursor: "pointer",
                      backgroundColor:
                        activeTheme === index ? "#DBDD26" : "#E6E6E6",
                    }}
                    onClick={() => setActiveTheme(index)}
                  >
                    {value}
                  </Box>
                );
              })}
            </div>
          </Box> */}
          {selectedFile && (
            <Box mt={2}>
              <Typography variant="p">Select Primary Colour</Typography>
              <Palette
                src={selectedFile}
                crossOrigin="anonymous"
                format="hex"
                colorCount={4}
              >
                {({ data, loading }) => {
                  if (data && !loading) {
                    setPrimaryColors(data);
                  }
                  return (
                    <div style={{ display: "flex", gap: "8px" }}>
                      {((data ? data : primaryColors) || []).map(
                        (color, index) => (
                          <Box
                            my={1}
                            style={{
                              border: `2px solid ${
                                activePrimaryColor === index
                                  ? "#000000"
                                  : "transparent"
                              }`,
                              height: "55px",
                              width: "55px",
                              borderRadius: "8px",
                              backgroundColor: color,
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              cursor: "pointer",
                            }}
                            onClick={() => setActivePrimaryColor(index)}
                          >
                            {activePrimaryColor === index && (
                              <div
                                style={{
                                  width: "20px",
                                  height: "20px",
                                  borderRadius: "50%",
                                  background: "#000000",
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                }}
                              >
                                <Check
                                  style={{ color: "#DBDD26", width: "14px" }}
                                />
                              </div>
                            )}
                          </Box>
                        )
                      )}
                    </div>
                  );
                }}
              </Palette>
            </Box>
          )}

          <Box mt={2} display={"flex"} flexDirection={"column"}>
            <Typography variant="p" mb={1}>
              Brand Name
            </Typography>
            <TextField
              placeholder="name"
              value={brandName}
              onChange={(e) => setBrandName(e.target.value)}
              inputProps={{
                style: {
                  borderRadius: "8px",
                  background: "#E6E6E6",
                  width: "400px",
                },
                maxLength: 24,
              }}
              InputProps={{
                disableUnderline: true,
                style: { width: "400px", background: "#E6E6E6" },
              }}
              rows={1}
            />
          </Box>
          <Box mt={2} display={"flex"} flexDirection={"column"}>
            <Typography variant="p" mb={1}>
              Short Description (character limit 150)
            </Typography>
            <TextField
              placeholder="Description"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              inputProps={{
                style: {
                  borderRadius: "8px",
                  background: "#E6E6E6",
                  width: "400px",
                },
                maxLength: 150,
              }}
              InputProps={{
                disableUnderline: true,
                style: { width: "400px", background: "#E6E6E6" },
              }}
              multiline={true}
              rows={4}
            />
          </Box>
        </Box>
        <Box>
          <Typography variant="p" style={{ marginLeft: "50px" }}>
            Menu Preview
          </Typography>
          <div style={{ transform: "scale(0.75)", marginTop: "-70px" }}>
            {primaryColors?.length ? (
              <ThemePreview
                theme={theme[activeTheme]}
                primaryColor={primaryColors[activePrimaryColor]}
                selectedFile={selectedFile}
                brandName={brandName}
                description={description}
              />
            ) : null}
          </div>
        </Box>
      </Box>
      <CustomDialogWithButtons
        open={updateDialog}
        handleClose={() => setUpdateDialog(false)}
        iconImage={<Mobile strokeWidth={0} width={32} height={32} />}
        message={
          "The updates to the menu branding have been successfully applied."
        }
        title={"Changes Updated"}
      />
    </Box>
  );
};

export default MenuBranding;
