import React, { useState } from "react";
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Box,
} from "@mui/material";
import { ReactComponent as PencilSimpleLine } from "../assets/Icons/PencilSimpleLine.svg";
import { ReactComponent as ListDashes } from "../assets/Icons/ListDashes.svg";
import FoodTypeIndication from "./FoodTypeIndication";
import CustomSwitch from "./CustomSwitch";

const foodTypeColor = { Veg: "#2D7D4D", Egg: "#ECB800", "Non-Veg": "#B82627" };

const StyledTableCell = ({ align, children }) => (
  <TableCell align={align} style={{ padding: 16 }}>
    {children}
  </TableCell>
);

const CustomMenuDialogTable = ({
  tableHeads,
  tableItems,
  categories,
  filter,
  updateItems,
  key,
  onEdit,
  isActionButton = true,
}) => {
  const [update, setUpdate] = useState(false);

  const updateItem = (index, status) => {
    updateItems(index, status);
    setUpdate(!update);
  };
  return (
    <TableContainer
      key={key}
      style={{
        width: "100%",
        height: "475px",
        marginTop: "16px",
        background: "#FAF8F4",
        borderRadius: "8px",
      }}
    >
      <Table>
        <TableHead
          style={{
            background: "#E1DFDC",
            position: "sticky",
            top: 0,
            zIndex: 1,
          }}
        >
          <TableRow>
            {tableHeads.map((value, index, array) => {
              return (
                <StyledTableCell align="start">
                  {value?.label || ""}
                </StyledTableCell>
              );
            })}
          </TableRow>
        </TableHead>
        <TableBody>
          {tableItems
            .filter((value) => {
              return filter?.category != "All" && filter?.category
                ? (value?.food_category || "")
                    .toLowerCase()
                    .includes((filter?.category || "").toLowerCase())
                : true;
            })
            .filter((value) =>
              filter?.name
                ? value.food_name
                    .toLowerCase()
                    .includes(filter?.name.toLowerCase())
                : true
            )
            .map((value, index) => {
              return (
                <TableRow>
                  {tableHeads.map((headValue, i) => {
                    return headValue?.key ? (
                      <StyledTableCell
                        align="start"
                        style={{ display: "flex" }}
                      >
                        <Box display={"flex"} alignItems={"center"} gap={"8px"}>
                          {headValue?.key === "food_name" && isActionButton ? (
                            <Box
                              style={{
                                border: `2px solid ${
                                  foodTypeColor[value.food_type]
                                }`,
                                padding: "4px",
                                borderRadius: "4px",
                              }}
                            >
                              <Box
                                style={{
                                  width: "8px",
                                  height: "8px",
                                  borderRadius: "50%",
                                  background: foodTypeColor[value.food_type],
                                }}
                              />
                            </Box>
                          ) : (
                            ""
                          )}
                          {headValue?.key === "price" ? "₹" : ""}{" "}
                          {headValue?.key && value[headValue?.key]
                            ? headValue?.key === "food_description"
                              ? value[headValue.key].substring(0, 30)
                              : value[headValue.key]
                            : ""}
                        </Box>
                      </StyledTableCell>
                    ) : i == 0 ? (
                      <StyledTableCell
                        align="start"
                        style={{ display: "flex" }}
                      >
                        {index > 8 ? "" : 0}
                        {index + 1}.
                      </StyledTableCell>
                    ) : (
                      isActionButton && (
                        <StyledTableCell align="start">
                          <Box
                            style={{
                              display: "flex",
                              alignItems: "center",
                              gap: "16px",
                            }}
                          >
                            <CustomSwitch
                              status={value.status}
                              onClick={(status) => {
                                updateItem(index, status);
                              }}
                            />
                            <PencilSimpleLine
                              style={{ cursor: "pointer" }}
                              onClick={() => onEdit(value)}
                            />
                          </Box>
                        </StyledTableCell>
                      )
                    );
                  })}
                </TableRow>
              );
            })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default CustomMenuDialogTable;
