import { ArrowBack } from "@mui/icons-material";
import { Box } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";

const BackArrow = () => {
  const navigate = useNavigate();
  return (
    <Box
      sx={{
        width: "40px",
        height: "40px",
        backgroundColor: "black",
        borderRadius: "8px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        cursor: "pointer",
      }}
      onClick={() => {
        navigate(-1);
      }}
    >
      <ArrowBack style={{ color: "white" }} />
    </Box>
  );
};

export default BackArrow;
