import React, { useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { Box, Button, InputAdornment, Typography } from "@mui/material";
import { ReactComponent as AttachIcon } from "../assets/Icons/Attach.svg";
import { ReactComponent as DownloadSampleIcon } from "../assets/Icons/DownloadSimple.svg";
import csv from "csvtojson";
import CustomMenuDialogTable from "./CustomMenuDialogTable";
import { parse } from "papaparse";
import ButtonWithIcon from "./ButtonWithIcon";
import CustomHeader from "./CustomHeader";

const tableHeads = [
  { label: "Name", key: "food_name" },
  { label: "Type", key: "food_type" },
  { label: "Description", key: "food_description" },
  { label: "Category", key: "food_category" },
  { label: "Price", key: "price" },
];

const CustomMenuCreationDialog = ({ open, handleClose, onSave }) => {
  const [foodItems, setFoodItems] = useState([]);

  const onFilechange = async (event) => {
    try {
      const file = event.target.files[0];
      const reader = new FileReader();
      let menuFoods = [];
      reader.onload = async function (event) {
        const csvData = event.target.result;
        const dataArray = parse(csvData, {
          step: (results, parser) => {
            const record = results.data;
            menuFoods.push(record.join(","));
          },
          complete: () => {},
        });
        setFoodItems(makeJson(menuFoods));
      };
      reader.readAsText(file);
    } catch (err) {
      console.error(err);
    }
  };

  const makeJson = (csvData) => {
    let headerKeys = csvData[0].split(",");
    headerKeys = headerKeys.map((value) => {
      return `${value != "Price" ? "food_" : ""}${value.toLowerCase()}`;
    });
    const jsonData = [];
    for (let i = 1; i < csvData.length; i++) {
      const row = csvData[i].split(",");
      const obj = {};
      for (let j = 0; j < headerKeys.length; j++) {
        obj[headerKeys[j]] = row[j];
      }
      jsonData.push(obj);
    }
    return jsonData;
  };

  const downloadSampleCSV = () => {
    const { menu, headers } = {
      menu: [
        {
          food_name: "Dal Makhni",
          food_description:
            "a popular North Indian dish where whole black lentils & red kidney beans are slow cooked with spices butter & cream",
          price: 130,
          food_category: "Main Course",
          food_type: "Veg",
        },
        {
          food_name: "Butter Chicken",
          food_description:
            "It is a type of curry made from chicken with a spiced tomato and butter (makhan) sauce",
          price: 280,
          food_category: "Main Course",
          food_type: "Non-Veg",
        },
        {
          food_name: "Bread Omelete",
          food_description:
            "A bread recipe that can be adapted to become a complete meal is that of a bread omelette",
          price: 65,
          food_category: "Snacks",
          food_type: "Egg",
        },
      ],
      headers: {
        food_name: "Name",
        food_description: "Description",
        food_category: "Category",
        food_type: "Type",
        price: "Price",
      },
    };

    const items = [...menu];
    const replacer = (key, value) => (value === null ? "" : value); // specify how you want to handle null values here
    const header = Object.keys(headers);
    let csv = items.map((row) =>
      header
        .map((fieldName) => {
          let value = row[fieldName]
            ? row[fieldName]
            : row.params
            ? row.params[fieldName]
            : null;

          return JSON.stringify(value, replacer);
        })
        .join(",")
    );
    csv.unshift(Object.values(headers).join(","));
    csv = csv.join("\r\n");

    //Download the file as CSV
    var downloadLink = document.createElement("a");
    var blob = new Blob(["\ufeff", csv]);
    var url = URL.createObjectURL(blob);
    downloadLink.href = url;
    downloadLink.download = `Menu-${Date.now()}.csv`;
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth="md"
      style={{ display: "flex", justifyContent: "end" }}
      PaperProps={{
        style: {
          height: "100%",
          maxHeight: "100%",
          borderRadius: "0px",
          margin: "0px",
        },
      }}
    >
      <DialogTitle
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        
        <CustomHeader headerText={"Add Menu"} />

        <Box>
          <ButtonWithIcon
            label={"Save"}
            onClick={() => {
              onSave(foodItems);
            }}
          />
          <IconButton
            edge="end"
            color="inherit"
            onClick={handleClose}
            aria-label="close"
            style={{ marginLeft: "20px" }}
          >
            <CloseIcon
              sx={{
                fontSize: "20px",
              }}
            />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent
        style={{
          backgroundColor: "white",
          height: "100%",
          marginTop: "40px",
          width: "600px",
        }}
      >
        <Box
          style={{
            width: "90%",
            display: "flex",
            gap: "20px",
          }}
        >
          <Box
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "8px",
            }}
          >
            <Typography fontFamily={'Manrope'} variant="p" fontWeight="500">
              Upload a csv file
            </Typography>
            <Button
              style={{
                padding: "10px 32px 10px 8px",
                color: "black",
                border: "1px solid black",
                borderRadius: "4px",
                display: "flex",
                justifyContent: "flex-start",
                gap: "8px",
                background: "#E6E6E6",
              }}
              onClick={() => {
                const input = document.getElementById("file_uploader");
                if (input) {
                  input.click();
                }
              }}
            >
              <input
                id="file_uploader"
                type="file"
                hidden
                onChange={onFilechange}
              />
              <AttachIcon style={{ width: "20px" }} /> Attach file
            </Button>
          </Box>
          <Box
            style={{
              display: "flex",
              justifyContent: "space-between",
              flexDirection: "column",
              alignItems: "start",
              gap: "8px",
            }}
          >
            <Typography fontFamily={'Manrope'} variant="p" fontWeight="500">
              Sample csv file
            </Typography>
            <Button
              style={{
                color: "black",
                border: "1px solid black",
                borderRadius: "4px",
                padding: "10px 32px 10px 8px",
                display: "flex",
                gap: "8px",
                justifyContent: "flex-start",
              }}
              onClick={() => downloadSampleCSV()}
            >
              <DownloadSampleIcon style={{ width: "20px" }} /> Download Sample
            </Button>
          </Box>
        </Box>
        <Box
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            marginTop: "20px",
          }}
        >
          <Typography fontFamily={'Manrope'} variant="p" fontWeight="500">
            Menu Items list
          </Typography>
          <CustomMenuDialogTable
            tableHeads={tableHeads}
            tableItems={foodItems}
            categories={[]}
            filter={{}}
            updateItems={() => {}}
            key={Date.now()}
            onEdit={() => {}}
            isActionButton={false}
          />
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default CustomMenuCreationDialog;
