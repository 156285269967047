import { Add, Remove } from "@mui/icons-material";
import React from "react";
import styled from "styled-components";

const StyledDivWrapper = styled.div`
  background-color: #dbdd26;
  border-radius: 7.28px;
  height: 44px;
  gap: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 8px;
  & .text-wrapper-8 {
    font-family: "Manrope";
    font-size: 16.4px;
    font-weight: 700;
    height: 22px;
    letter-spacing: 0;
    line-height: normal;
    text-align: center;
    width: 29px;
  }

  & .plus {
    height: 15px;
    width: 15px;
  }

  & .minus {
    height: 15px;
    width: 15px;
  }
`;

export const DivWrapper = ({ primaryColor, theme }) => {
  return (
    <StyledDivWrapper
      style={{
        backgroundColor: primaryColor,
        color: theme == "Dark" ? "black" : "white",
      }}
    >
      <Remove />
      <div className="text-wrapper-8">1</div>
      <Add />
    </StyledDivWrapper>
  );
};
