import React from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import Sidebar from "./sidebar";
import Dashboard from "../pages/Dashboard/dashboard";
import FoodOrders from "../pages/Orders/FoodOrders";
import FoodTable from "../pages/Orders/FoodTable";
import FloorTable from "../pages/FloorTable/FloorTable";
import FoodParty from "../pages/Party/FoodParty";
import FoodBilling from "../pages/Billing/FoodBilling";
import FinalBilling from "../pages/Billing/FinalBilling";
import FoodMenu from "../pages/Menu/FoodMenu";
import MenuBranding from "../pages/Settings/MenuBranding";
import BillingSetting from "../pages/Settings/BillingSetting";
import PublicProfile from "../pages/Profile/PublicProfile";
import WaiterDashboard from "../pages/Waiter/WaiterDashboard";

const ProtectedRoutes = () => {
  const token = localStorage.getItem("jwtToken");

  if (!token) {
    return <Navigate to="/login" />;
  }
  return (
    <div style={{ display: "flex", height: "100vh", overflow: "hidden" }}>
      <Sidebar />
      <Routes>
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/" element={<FoodOrders />} />
        <Route path="/tables" element={<FoodOrders />} />
        <Route path="/tables/table/:tableNo" element={<FoodTable />} />
        <Route path="/party" element={<FoodParty />} />
        <Route path="/billing" element={<FoodBilling />} />
        <Route
          path="/billing/final-billing/:transactionId"
          element={<FinalBilling />}
        />
        <Route path="/floor-tables" element={<FloorTable />} />
        <Route path="/menu" element={<FoodMenu />} />
        <Route path="/menu-branding" element={<MenuBranding />} />
        <Route path="/billing-setting" element={<BillingSetting />} />
        <Route path="/public-profile" element={<PublicProfile />} />
        <Route path="/waiter" element={<WaiterDashboard />} />
        {/* <Route path="/logout" element={<Logout />} /> */}
      </Routes>
    </div>
  );
};

export default ProtectedRoutes;
