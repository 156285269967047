import { Email, Lock } from "@mui/icons-material";
import {
  Box,
  Button,
  Grid,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import LoginBg from "../../assets/Loginpagebg.png";
import { Navigate, useNavigate } from "react-router-dom";
import { URL } from "../../config/config";
import axios from "axios";
import { ReactComponent as EmailLogin } from "../../assets/Icons/email_login.svg";
import { ReactComponent as PasswordLogin } from "../../assets/Icons/password_login.svg";
import setAuthToken from "../../utils/setAuthentication";

const Login = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const loginClick = async (email, password) => {
    const userData = {
      email,
      password,
    };
    await axios
      .post(URL + "clients/login", userData)
      .then((value) => {
        const { token } = value.data.data;
        localStorage.setItem("jwtToken", token);
        setAuthToken(`Bearer ${localStorage.getItem("jwtToken")}`);
        navigate("/tables");
      })
      .catch((value) => {
        alert("Unable to login");
      });
  };

  return (
    <Grid style={{ width: "100vw", height: "100vh", display: "flex" }}>
      <Box sx={{ width: "50%", height: "100%" }}>
        <img src={LoginBg} style={{ width: "100%", height: "100%" }} />
      </Box>
      <Box
        sx={{
          width: "50%",
          height: "100%",
          display: "flex",
          padding: "0px 56px",
          flexDirection: "column",
          justifyContent: "center",
          gap: "16px",
        }}
      >
        <Box>
          <Typography>Email Id</Typography>
          <TextField
            placeholder="Enter Email"
            onChange={(e) => setEmail(e.target.value)}
            InputProps={{
              style: {
                borderRadius: "8px",
                background: "#E6E6E6",
                width: "450px",
                marginTop: "8px",
              },
              startAdornment: (
                <InputAdornment position="start">
                  <EmailLogin style={{ width: "20px" }} />
                </InputAdornment>
              ),
            }}
          />
        </Box>
        <Box mt={1}>
          <Typography>Password</Typography>
          <TextField
            placeholder="Enter Password"
            onChange={(e) => setPassword(e.target.value)}
            InputProps={{
              style: {
                borderRadius: "8px",
                background: "#E6E6E6",
                width: "450px",
                marginTop: "8px",
              },
              startAdornment: (
                <InputAdornment position="start">
                  <PasswordLogin style={{ width: "20px" }} />
                </InputAdornment>
              ),
            }}
          />
        </Box>
        <Button
          disableRipple
          sx={{
            background: "black",
            color: "#DBDD26",
            textTransform: "capitalize",
            padding: "8px",
            width: "450px",
            marginTop: "40px",
            "&:hover": {
              backgroundColor: "black",
            },
          }}
          onClick={() => loginClick(email, password)}
        >
          Login
        </Button>
      </Box>
    </Grid>
  );
};

export default Login;
