import React from "react";
import { DivWrapper } from "./sections/DivWrapper";
import { Overlap } from "./sections/Overlap";
import { SectionComponentNode } from "./sections/SectionComponentNode";
import styled from "styled-components";
import { Box } from "@mui/material";

const StyledThemePreview = styled.div`
  border: 4px solid;
  border-color: #000000;
  border-radius: 18.2px;
  padding-bottom: 32px;
  overflow: hidden;
  position: relative;
  width: 355px;

  & .rectangle-2 {
    background-color: #f5f5f5;
    border-radius: 7.28px;
    height: 44px;
    left: 448px;
    position: absolute;
    top: 561px;
    width: 109px;
  }

  & .group-5 {
    height: 44px;
    width: 111px;
  }

  & .overlap-group-4 {
    background-color: #dbdd261a;
    border: 0.91px solid;
    border-color: #dbdd26;
    border-radius: 7.28px;
    height: 44px;
    position: relative;
    width: 109px;
  }

  & .text-wrapper-14 {
    color: #dbdd26;
    font-family: "Manrope";
    font-size: 16.4px;
    font-weight: 700;
    height: 22px;
    left: 27px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 10px;
    width: 52px;
  }

  & .text-wrapper-15 {
    color: #ffffff;
    font-family: "Manrope";
    font-size: 16.4px;
    font-weight: 500;
    height: 22px;
    left: 15px;
    letter-spacing: 0;
    line-height: normal;
    top: 419px;
    width: 182px;
  }

  & .text-wrapper-16 {
    font-family: "Manrope";
    font-size: 16.4px;
    font-weight: 500;
    height: 22px;
    letter-spacing: 0;
    line-height: normal;
    width: 182px;
  }

  & .text-wrapper-17 {
    color: #ffffff;
    font-family: "Manrope";
    font-size: 16.4px;
    font-weight: 500;
    height: 22px;
    left: 15px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 571px;
    width: 182px;
  }

  & .food-type-8 {
    border: 0.91px solid !important;
    border-radius: 2.27px !important;
    height: 15px !important;
    left: 15px !important;
    position: absolute !important;
    top: 398px !important;
    width: 15px !important;
  }

  & .food-type-9 {
    border-radius: 3.18px !important;
    height: 6px !important;
    left: 3px !important;
    top: 3px !important;
    width: 6px !important;
  }

  & .food-type-10 {
    border: 0.91px solid !important;
    border-radius: 2.27px !important;
    height: 15px !important;
    left: 15px !important;
    position: absolute !important;
    top: 549px !important;
    width: 15px !important;
  }

  & .text-wrapper-18 {
    color: #999999;
    font-family: "Manrope";
    font-size: 12.7px;
    font-weight: 500;
    width: 182px;
    margin: 8px 0px;
  }

  & .img {
    height: 1px;
    width: 355px;
  }

  & .text-wrapper-19 {
    color: #ffffff;
    font-family: "Manrope";
    font-size: 18.2px;
    font-weight: 700;
    height: 29px;
    left: 15px;
    letter-spacing: 0;
    line-height: normal;
    top: 347px;
    width: 182px;
  }

  & .caret-down {
    height: 16px;
    left: 310px;
    top: 353px;
    width: 16px;
  }

  & .food-type-instance {
    border: 0.91px solid !important;
    border-radius: 2.27px !important;
    height: 15px !important;
    left: 15px !important;
    top: 0 !important;
    width: 15px !important;
  }

  & .text-wrapper-11 {
    font-family: "Manrope";
    font-size: 12.7px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: normal;
    margin-top: -0.31px;
    position: relative;
    width: fit-content;
  }
`;

const ThemePreview = ({
  theme,
  primaryColor,
  selectedFile,
  brandName,
  description,
}) => {
  return (
    <StyledThemePreview
      style={{ background: theme === "Dark" ? "Black" : "white" }}
    >
      <SectionComponentNode
        theme={theme}
        primaryColor={primaryColor}
        selectedFile={selectedFile}
        brandName={brandName}
        description={description}
      />
      <Box
        display={"flex"}
        padding={"0px 16px"}
        mt={2}
        alignItems={"center"}
        justifyContent={"space-between"}
      >
        <div
          className="text-wrapper-19"
          style={{ color: theme === "Dark" ? "#ffffff" : "#000000" }}
        >
          Pasta
        </div>
        <img
          className="caret-down"
          alt="Caret down"
          src="https://c.animaapp.com/qmMdcQ0L/img/caretdown.svg"
        />
      </Box>
      <Box
        padding={"0px 16px"}
        display={"flex"}
        justifyContent={"space-between"}
        mt={2}
        style={{ borderBottom: "1px solid #333333", paddingBottom: "12px" }}
      >
        <Box>
          <div
            className="text-wrapper-15"
            style={{ color: theme === "Dark" ? "#ffffff" : "#000000" }}
          >
            Aglio-Olio
          </div>
          <p className="text-wrapper-18">Pasta tossed in red sause</p>
          <div className="text-wrapper-16">₹ 355</div>
        </Box>
        <DivWrapper primaryColor={primaryColor} theme={theme} />
      </Box>
      <Box
        padding={"0px 16px"}
        display={"flex"}
        justifyContent={"space-between"}
        mt={2}
        style={{ borderBottom: "1px solid #333333", paddingBottom: "12px" }}
      >
        <Box>
          <div
            className="text-wrapper-15"
            style={{ color: theme === "Dark" ? "#ffffff" : "#000000" }}
          >
            Arabiata
          </div>
          <p className="text-wrapper-18">
            Pasta tossed in olive oil,garlic and spring vegetables
          </p>
          <div className="text-wrapper-16">₹ 355</div>
        </Box>
        <div className="group-5">
          <div
            className="overlap-group-4"
            style={{
              backgroundColor: `${primaryColor}15`,
            }}
          >
            <div
              className="text-wrapper-14"
              style={{
                color: `${primaryColor}`,
              }}
            >
              + ADD
            </div>
          </div>
        </div>
      </Box>
      <Box
        padding={"0px 16px"}
        display={"flex"}
        justifyContent={"space-between"}
        mt={2}
      >
        <Box>
          <div
            className="text-wrapper-15"
            style={{ color: theme === "Dark" ? "#ffffff" : "#000000" }}
          >
            Arabiata
          </div>
          <p className="text-wrapper-18">
            Pasta tossed in olive oil,garlic and spring vegetables
          </p>
          <div className="text-wrapper-16">₹ 355</div>
        </Box>
        <div className="group-5">
          <div
            className="overlap-group-4"
            style={{
              backgroundColor: `${primaryColor}15`,
            }}
          >
            <div
              className="text-wrapper-14"
              style={{
                color: `${primaryColor}`,
              }}
            >
              + ADD
            </div>
          </div>
        </div>
      </Box>
      <Overlap primaryColor={primaryColor} theme={theme} />
    </StyledThemePreview>
  );
};

export default ThemePreview;
