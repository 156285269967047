import React from "react";
import styled from "styled-components";

const StyledOverlapGroupWrapper = styled.div`
  background-color: #191919;
  border-radius: 7.28px;
  height: 44px;
  width: 213px;
  display: flex;
  align-items: center;
  justify-content: start;
  padding: 0px 16px;
  gap: 8px;
  flex: 1;
  & .text-wrapper-7 {
    color: #999999;
    font-family: "Manrope";
    font-size: 14.6px;
    font-weight: 400;
    left: 44px;
    letter-spacing: 0;
    line-height: normal;
    width: 164px;
  }

  & .magnifying-glass {
    width: 22px;
  }
`;

export const OverlapGroupWrapper = ({ theme }) => {
  return (
    <StyledOverlapGroupWrapper
      style={{ backgroundColor: theme === "Dark" ? "#191919" : "#F5F5F5" }}
    >
      <img
        className="magnifying-glass"
        alt="Magnifying glass"
        src="https://c.animaapp.com/qmMdcQ0L/img/magnifyingglass.svg"
      />
      <div className="text-wrapper-7">Search Food item</div>
    </StyledOverlapGroupWrapper>
  );
};
