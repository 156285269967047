import {
  Box,
  Grid,
  Typography,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from "@mui/material";
import React from "react";
import CustomHeader from "../../common/CustomHeader";
import { ReactComponent as ListDash } from "../../assets/Icons/ListDashes.svg";
import { ReactComponent as RupeeSign } from "../../assets/Icons/Vector.svg";
import { ReactComponent as GraphIcon } from "../../assets/Icons/GraphIcon.svg";
import CustomGraph from "../../common/CustomGraph";

const DropDown = () => {
  return (
    <FormControl
      style={{
        marginLeft: "auto",
        minWidth: 120,
        border: "2px solid #1D2020",
        borderRadius: "8px",
        outline: "none",
      }}
    >
      <Select
        labelId="demo-simple-select-label"
        id="period-select"
        defaultValue={"Week"}
      >
        <MenuItem value={"Week"}>This Week</MenuItem>
        <MenuItem value={"Month"}>This Month</MenuItem>
        <MenuItem value={"Year"}>This Year</MenuItem>
      </Select>
    </FormControl>
  );
};

const TypoLabelValueBox = ({ value, label }) => {
  return (
    <Box
      style={{
        width: "50%",
      }}
    >
      <Typography fontFamily={'Manrope'} variant="h4">{value}</Typography>
      <Typography fontFamily={'Manrope'} variant="h6">{label}</Typography>
    </Box>
  );
};

const TopBox = ({ label1, value1, label2, value2, icon, iconLabel }) => {
  return (
    <Box
      style={{
        width: "50%",
        background: "#FAF8F4",
        borderRadius: "8px",
        border: "2px solid #E1DFDC",
      }}
    >
      <Grid
        style={{
          width: "100%",
          display: "flex",
          alignItems: "center",
          background: "#E1DFDC",
          borderTopLeftRadius: "8px",
          borderTopRightRadius: "8px",
          padding: "24px",
          gap: "16px",
        }}
      >
        <Box
          style={{
            height: "40px",
            width: "40px",
            border: "2px solid #1D2020",
            borderRadius: "4px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {icon}
        </Box>
        <Typography fontFamily={'Manrope'} variant="h6">{iconLabel}</Typography>
      </Grid>
      <Grid
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          gap: "32px",
          width: "100%",
          padding: "32px",
        }}
      >
        <TypoLabelValueBox value={value1} label={label1} />
        <Box
          style={{
            height: "65px",
            width: "2px",
            background: "#CCCCCC",
          }}
        />
        <TypoLabelValueBox value={value2} label={label2} />
      </Grid>
    </Box>
  );
};

const BottomBox = ({ label1, value1, label2, value2, icon, iconLabel }) => {
  return (
    <Box
      mt={4}
      style={{
        width: "100%",
        height: "55%",
        background: "#FAF8F4",
        borderRadius: "8px",
        overflow: "hidden",
        border: "2px solid #E1DFDC",
      }}
    >
      <Grid
        style={{
          width: "100%",
          display: "flex",
          alignItems: "center",
          background: "#E1DFDC",
          padding: "24px",
          gap: "16px",
        }}
      >
        <Box
          style={{
            height: "40px",
            width: "40px",
            border: "2px solid #1D2020",
            borderRadius: "4px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <GraphIcon />
        </Box>
        <Typography fontFamily={'Manrope'} variant="h6">Earnings Graph</Typography>
        <DropDown />
      </Grid>
      <Grid
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          gap: "32px",
          height: "calc(100% - 96px)",
          width: "100%",
          padding: "32px",
        }}
      >
        <CustomGraph />
      </Grid>
    </Box>
  );
};

const Dashboard = () => {
  return (
    <Box sx={{ padding: 6, width: "calc(100% - 360px)" }}>
      <CustomHeader headerText={"Dashboard"} />
      <Grid mt={4} display={"flex"} gap={"40px"}>
        <TopBox
          label1={"New Orders"}
          value1={"02"}
          label2={"Completed Orders"}
          value2={"12"}
          icon={<ListDash />}
          iconLabel={"Today’s Orders"}
        />
        <TopBox
          label1={"Today’s"}
          value1={"₹ 5,600"}
          label2={"Total"}
          value2={"₹ 80,600"}
          icon={<RupeeSign />}
          iconLabel={"Earnings"}
        />
      </Grid>
      <BottomBox />
    </Box>
  );
};

export default Dashboard;
