import React from "react";

const CustomSwitch = ({ status, onClick }) => {
  return (
    <div style={{ transform: "scale(0.75)" }}>
      <div
        onClick={() => {
          onClick(!status);
        }}
        style={{
          width: "56px",
          backgroundColor: status ? "#DADB27" : "#717171",
          borderRadius: "20px",
          display: "flex",
          alignItems: "center",
          padding: "4px",
          justifyContent: status ? "end" : "start",
          transition: "all 0.5s ease-in-out",
          cursor: "pointer",
        }}
      >
        <div
          style={{
            width: "24px",
            height: "24px",
            backgroundColor: status ? "#F4F539" : "#8F8F8F",
            borderRadius: "50%",
            padding: "4px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            transition: "all 0.4s ease-in-out",
          }}
        >
          {status ? (
            <div
              style={{
                width: "16px",
                height: "16px",
                borderRadius: "50%",
                border: "2px solid black",
              }}
            ></div>
          ) : (
            <div
              style={{
                width: "16px",
                height: "4px",
                borderRadius: "4px",
                background: "#CCCCCC",
              }}
            ></div>
          )}
        </div>
      </div>
    </div>
  );
};

export default CustomSwitch;
