import { Button } from "@mui/material";
import React from "react";

const CustomButton = ({ label, icon, onClick }) => {
  return (
    <Button
      onClick={onClick}
      sx={{
        background: "transparent",
        color: "black",
        border: "2px solid black",
        borderRadius: "8px",
        textTransform: "capitalize",
        width: "130px",
        height: "40px",
      }}
    >
      {label}
    </Button>
  );
};

export default CustomButton;
