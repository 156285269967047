import { Box, Grid, Paper, Tooltip, Typography } from "@mui/material";
import React, { useState, useEffect } from "react";
import CustomHeader from "../../common/CustomHeader";
import Twofactorauthentication from "../../assets/Twofactorauthentication.png";
import ButtonWithIcon from "../../common/ButtonWithIcon";
import { ReactComponent as PlusSquare } from "../../assets/Icons/PlusSquare.svg";
import { ReactComponent as Vector_1 } from "../../assets/Icons/Vector_1.svg";
import { ReactComponent as PizzaSlice } from "../../assets/Icons/Pizza_slice.svg";
import CustomFloorCreationDialog from "../../common/CustomFloorCreationDialog";
import { connect } from "react-redux";
import { createFloor, updateFloor, deleteFloor } from "../../redux/floors";
import { useLocation } from "react-router-dom";
import { fetchFloors, getClientProfile } from "../../redux/floors";
import CustomDialogWithButtons from "../../common/CustomDialogWIthButton";
import { ReactComponent as TrashWhite } from "../../assets/Icons/TrashWhite.svg";
import { ReactComponent as DeleteBin } from "../../assets/Icons/DeleteBin.svg";

const FloorTables = (props) => {
  const location = useLocation();
  const state = location.state;
  const [dialogOpen, setDialogOpen] = useState(false);
  const [key, setKey] = useState(Date.now());
  const [deleteDialog, setDeleteDialog] = useState(false);
  const [dialog, setDialog] = useState(false);
  const [updateDialog, setUpdateDialog] = useState(false);
  const [selectedFloor, setSelectedFloor] = useState({});
  const handleOpenDialog = () => {
    setDialogOpen(true);
  };

  useEffect(() => {
    if (!dialogOpen && state?.addFloor) {
      handleOpenDialog();
    }
  }, [state?.addFloor]);

  const handleCloseDialog = () => {
    setDialogOpen(false);
  };

  const handleFloorChanges = async (floor) => {
    if (floor?._id) {
      const response = await props.updateFloor(floor);
      if (response) {
        setDialogOpen(false);
        setUpdateDialog(true);
      } else {
        alert("Something went wrong");
      }
    } else {
      const response = await props.createFloor(floor);
      if (response) {
        setDialogOpen(false);
        setDialog(true);
      } else {
        alert("Something went wrong");
      }
    }
  };

  useEffect(() => {
    props.fetchFloors();
    props.getClientProfile();
  }, []);

  // Table component representing each individual table
  const Table = ({ place }) => (
    <Grid item xs={6} sm={4} md={3} lg={2} xl={1.5} mb={4}>
      <Paper
        elevation={0}
        sx={{
          bgcolor: "#FAF8F4",
          padding: 2,
          display: "flex",
          flexDirection: "column",
          position: "relative",
          borderRadius: "8px",
          border: "1px solid #cccccc",
        }}
      >
        <Box
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Typography
            variant="h6"
            fontWeight={"bold"}
            style={{ textTransform: "capitalize" }}
          >
            {place.floor_name}
          </Typography>
          <Box gap={"8px"} display={"flex"}>
            <ButtonWithIcon
              label={"Edit"}
              onClick={() => {
                setKey(Date.now());
                setSelectedFloor(place);
                handleOpenDialog();
              }}
            />
            <Box
              style={{
                backgroundColor: "#000000",
                padding: "8px 12px",
                borderRadius: "4px",
                display: "flex",
                alignItems: "center",
                cursor: "pointer",
              }}
              onClick={() => {
                setSelectedFloor(place);
                setDeleteDialog(true);
              }}
            >
              <TrashWhite />
            </Box>
          </Box>
        </Box>
        <Grid
          display={"flex"}
          sx={{
            flexWrap: "wrap",
          }}
          gap={4}
          mt={4}
        >
          {place.tables.map((v, i) => (
            <Box
              key={i}
              sx={{
                position: "relative",
                width: "85px",
                height: "85px",
                borderRadius: "8px",
                backgroundColor: "#CCCCCC",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                cursor: "pointer",
              }}
            >
              <Tooltip title={v.status ? "" : "inactive"}>
                <Typography
                  fontFamily={"Manrope"}
                  variant="h4"
                  fontWeight={500}
                  color={v.status ? "black" : "#717171"}
                >
                  {v.table_name.substring(0, 4)}
                </Typography>
              </Tooltip>
            </Box>
          ))}
        </Grid>
      </Paper>
    </Grid>
  );

  return (
    <Box
      sx={{
        padding: 6,
        width: "calc(100% - 360px)",
        height: "auto",
        overflowY: "auto",
      }}
    >
      <Box
        display={"flex"}
        // alignItems={"center"}
        justifyContent={"space-between"}
      >
        <CustomHeader headerText={"Floor & Tables"} />
        {props.floors.length ? (
          <ButtonWithIcon
            icon={<Vector_1 style={{ marginRight: "10px", width: "16px" }} />}
            label={"Add New Floor"}
            onClick={() => {
              setSelectedFloor({});
              handleOpenDialog();
            }}
          />
        ) : null}
      </Box>
      {props.floors.length ? (
        <Box mt={8}>
          {props.floors.map((value, index) => {
            return <Table key={index} place={value} />;
          })}
        </Box>
      ) : (
        <Box
          style={{
            width: "100%",
            height: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
            gap: "16px",
          }}
        >
          <img src={Twofactorauthentication} style={{ width: "350px" }} />
          <ButtonWithIcon
            icon={<PlusSquare style={{ marginRight: "4px", width: "24px" }} />}
            label={"Create Floor & Add Tables"}
            onClick={() => handleOpenDialog()}
          />
        </Box>
      )}

      <CustomFloorCreationDialog
        key={Date.now()}
        open={dialogOpen}
        handleClose={handleCloseDialog}
        floors={selectedFloor}
        setFloors={(floor) => {
          handleFloorChanges(floor);
        }}
      />
      <CustomDialogWithButtons
        open={dialog}
        handleClose={() => setDialog(false)}
        iconImage={<Vector_1 strokeWidth={0} width={32} height={32} />}
        message={
          "New floors and tables created successfully, available soon for usage."
        }
        title={"Floor and Tables Created"}
      />
      <CustomDialogWithButtons
        open={updateDialog}
        handleClose={() => setUpdateDialog(false)}
        iconImage={<PizzaSlice strokeWidth={0} width={32} height={32} />}
        message={
          "The updates to the menu branding have been successfully applied."
        }
        title={"Changes Updated"}
      />
      <CustomDialogWithButtons
        open={selectedFloor && deleteDialog}
        handleClose={() => setDeleteDialog(false)}
        iconImage={<DeleteBin strokeWidth={0} width={32} height={32} />}
        message={"Are you certain about deleting the floor?"}
        title={""}
        onDelete={async () => {
          const response = await props.deleteFloor({ ...selectedFloor });
          if (response) {
            setDeleteDialog(false);
          } else {
            alert("Something went wrong");
          }
        }}
        isDelete={true}
      />
    </Box>
  );
};

const connectedFloorTables = connect(
  (state) => ({
    floors: state.floors.floors,
    isFloorAdded: state.floors.isFloorAdded,
  }),
  { createFloor, updateFloor, fetchFloors, deleteFloor, getClientProfile }
)(FloorTables);

export default connectedFloorTables;
