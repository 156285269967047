import {
  Box,
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  SvgIcon,
} from "@mui/material";
import React from "react";
import { Link, useLocation } from "react-router-dom";
import { styled } from "@mui/material/styles";
import { withStyles } from "@mui/styles";
import Logo from "../assets/Asset 1@4x.png";
import { ReactComponent as DashboardIcon } from "../assets/Icons/Dashboard.svg";
import { ReactComponent as BillingIcon } from "../assets/Icons/Billing.svg";
import { ReactComponent as OrdersIcon } from "../assets/Icons/Orders.svg";
import { ReactComponent as MenuIcon } from "../assets/Icons/Menu.svg";
import { ReactComponent as FloorsIcon } from "../assets/Icons/Floors.svg";
import { ReactComponent as SettingsIcon } from "../assets/Icons/Settings.svg";
import { ReactComponent as DeviceMobile } from "../assets/Icons/DeviceMobile.svg";
import { ReactComponent as SquaresFour } from "../assets/Icons/SquaresFour.svg";
import { ReactComponent as UserGear } from "../assets/Icons/UserGear.svg";
import { ReactComponent as UserCircle } from "../assets/Icons/UserCircle.svg";
import { ReactComponent as LogoutIcon } from "../assets/Icons/Logout.svg";
import io from "socket.io-client";

import Clock from "../common/Clock";
import { MenuBook, MobileOff, Smartphone } from "@mui/icons-material";
import { useEffect } from "react";
const drawerWidth = 264;

const StyledDrawer = styled(Drawer)({
  width: drawerWidth,
  flexShrink: 0,
  "& .MuiDrawer-paper": {
    width: drawerWidth,
    boxSizing: "border-box",
    backgroundColor: "black",
    color: "white",
    display: "flex",
    padding: "56px 0px 0px 28px",
    justifyContent: "space-between",
  },
});

const menuItems = [
  {
    text: "Tables",
    icon: <SquaresFour strokeWidth={0} width={24} height={24} />,
    path: "/tables",
  },
  {
    text: "Billing",
    icon: <BillingIcon strokeWidth={0} width={24} height={24} />,
    path: "/billing",
  },
  {
    text: "Floor & Tables",
    icon: <FloorsIcon strokeWidth={0} width={24} height={24} />,
    path: "/floor-tables",
  },
  {
    text: "Menu",
    icon: <MenuIcon strokeWidth={0} width={24} height={24} />,
    path: "/menu",
  },
  {
    text: "Menu Branding",
    icon: <DeviceMobile strokeWidth={0} width={24} height={24} />,
    path: "/menu-branding",
  },

  {
    text: "Waiter",
    icon: <UserCircle strokeWidth={0} width={24} height={24} />,
    path: "/waiter",
  },
  {
    text: "Profile",
    icon: <UserGear strokeWidth={0} width={24} height={24} />,
    path: "/public-profile",
  },
  {
    text: "Logout",
    icon: <LogoutIcon strokeWidth={0} width={24} height={24} />,
    path: "",
  },
];

const Sidebar = (props) => {
  const { classes } = props;

  const location = useLocation();

  let pathName = "/" + location.pathname.split("/")?.[1];

  return (
    <Box display="flex" justifyContent="space-between">
      <StyledDrawer variant="permanent" anchor="left">
        <Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-start",
              backgroundColor: "black",
              marginBottom: "82px",
            }}
          >
            <img src={Logo} alt="logo" className={classes.logo} />
          </Box>
          <List>
            {menuItems.map((item, index) => (
              <ListItem
                button
                key={item.text}
                component={Link}
                onClick={() => {
                  if (!item.path) {
                    localStorage.removeItem("jwtToken");
                  }
                }}
                to={item.path || "/login"}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "start",
                  padding: "0px 0px 0px 0px",
                  marginBottom: "20px",
                  gap: "8px",
                }}
              >
                <ListItemIcon
                  className={
                    pathName === item.path
                      ? classes.activeListItemIconStyle
                      : classes.listItemIconStyle
                  }
                  sx={{
                    color: pathName === item.path ? "#DBDD26" : "#E6E6E6",
                    minWidth: "32px",
                  }}
                >
                  {item.icon}
                </ListItemIcon>
                <ListItemText
                  sx={{
                    color: pathName === item.path ? "#DBDD26" : "#E6E6E6",
                  }}
                  primary={item.text}
                />
              </ListItem>
            ))}
          </List>
        </Box>
        <ListItem
          disableRipple
          button
          onClick={null}
          style={{ cursor: "default" }}
        >
          <Clock />
        </ListItem>
      </StyledDrawer>
    </Box>
  );
};

const useStyles = (theme) => ({
  logo: {
    width: 56,
  },
  listItemIconStyle: {
    "& path": {
      stroke: "white",
      fill: "white",
    },
  },
  activeListItemIconStyle: {
    "& path": {
      stroke: "#DBDD26",
      fill: "#DBDD26",
    },
  },
});

export default withStyles(useStyles)(Sidebar);
