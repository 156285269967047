import React from "react";
import { FoodType } from "../../../../components/FoodType";
import styled from "styled-components";

const StyledFrame = styled.div`
  align-items: center;
  border: 0.91px solid;
  border-color: #333333;
  border-radius: 7.28px;
  display: inline-flex;
  gap: 7.28px;
  height: 29px;
  left: 168px;
  padding: 5.46px 7.28px;
  top: 288px;
  & .food-type-2 {
    border: 0.91px solid !important;
    border-radius: 2.27px !important;
    height: 14.56px !important;
    position: relative !important;
    width: 14.56px !important;
  }

  & .food-type-3 {
    border-radius: 3.18px !important;
    height: 6px !important;
    left: 3px !important;
    top: 3px !important;
    width: 6px !important;
  }

  & .text-wrapper-9 {
    color: #ffffff;
    font-family: "Manrope";
    font-size: 12.7px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: normal;
    margin-top: -0.31px;
    position: relative;
    width: fit-content;
  }

  & .x {
    height: 12.74px;
    position: relative;
    width: 12.74px;
  }
`;

export const Frame = ({ theme }) => {
  return (
    <StyledFrame>
      <FoodType
        className="food-type-2"
        ellipseClassName="food-type-3"
        property1="veg"
      />
      <div
        className="text-wrapper-11"
        style={{ color: theme === "Dark" ? "#ffffff" : "#000000" }}
      >
        Veg
      </div>
      <img
        className="x"
        alt="X"
        src="https://c.animaapp.com/qmMdcQ0L/img/x.svg"
      />
    </StyledFrame>
  );
};
