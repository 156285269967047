import React, { useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Login from "../pages/Login/login";
import { fetchFloors } from "../redux/floors";
import { connect } from "react-redux";
import { withStyles } from "@mui/styles";
import ProtectedRoutes from "../components/protected.route";

function App(props) {

  return (
    <Router>
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/*" element={<ProtectedRoutes />} />
      </Routes>
    </Router>
  );
}

const styles = {};

const connectedApp = connect(
  (state) => ({
    floors: state.floors.floors,
  }),
  {
    fetchFloors,
  }
)(App);
export default withStyles(styles)(connectedApp);
