import {
  Box,
  FormControlLabel,
  InputAdornment,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import CustomHeader from "../../common/CustomHeader";
import { Palette } from "color-thief-react";
import { Check } from "@mui/icons-material";
import CustomSwitch from "../../common/CustomSwitch";

const BillingSetting = () => {
  const [checked, setChecked] = useState(false);

  return (
    <Box sx={{ padding: 6, width: "calc(100% - 360px)" }}>
      <CustomHeader headerText={"Billing"} />
      <Box mt={8} display={"flex"} alignItems={"center"}>
        <Typography variant="p" width={"120px"}>
          CGST
        </Typography>
        <TextField
          placeholder="2.5"
          InputProps={{
            style: {
              borderRadius: "8px",
              background: "#E6E6E6",
              width: "80px",
            },
            endAdornment: <InputAdornment position="start">%</InputAdornment>,
          }}
        />
      </Box>
      <Box display={"flex"} alignItems={"center"} my={1}>
        <Typography variant="p" width={"120px"}>
          SGST
        </Typography>
        <TextField
          placeholder="2.5"
          InputProps={{
            style: {
              borderRadius: "8px",
              background: "#E6E6E6",
              width: "80px",
            },
            endAdornment: <InputAdornment position="start">%</InputAdornment>,
          }}
        />
      </Box>
      <Box display={"flex"} alignItems={"center"}>
        <Typography variant="p" width={"120px"}>
          Roundoff
        </Typography>
        <CustomSwitch
          status={checked}
          onClick={(status) => setChecked(status)}
        />
      </Box>
    </Box>
  );
};

export default BillingSetting;
