import React from "react";
import { FoodType } from "../../../../components/FoodType";
import styled from "styled-components";

const StyledDiv = styled.div`
  align-items: center;
  border: 0.91px solid;
  border-color: #333333;
  border-radius: 7.28px;
  display: inline-flex;
  gap: 7.28px;
  height: 29px;
  left: 168px;
  padding: 5.46px 7.28px;
  top: 288px;

  & .food-type-6 {
    border: 0.91px solid !important;
    border-radius: 2.27px !important;
    height: 14.56px !important;
    width: 14.56px !important;
  }

  & .food-type-7 {
    height: 6px !important;
    left: 3px !important;
    top: 3px !important;
    width: 7px !important;
  }
`;

export const Div = ({ theme }) => {
  return (
    <StyledDiv>
      <FoodType
        className="food-type-6"
        ellipseClassName="food-type-7"
        polygon="https://c.animaapp.com/qmMdcQ0L/img/polygon-1-1.svg"
        property1="non-veg"
      />
      <div
        className="text-wrapper-11"
        style={{ color: theme === "Dark" ? "#ffffff" : "#000000" }}
      >
        Non - Veg
      </div>
    </StyledDiv>
  );
};
