import { Box, CircularProgress, TextField, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import BackArrow from "../../common/BackArrow";
import { ReactComponent as MenuIcon } from "../../assets/Icons/Menu.svg";
import ButtonWithIcon from "../../common/ButtonWithIcon";
import CustomTable from "../../common/CustomTable";
import CustomMessageDialog from "../../common/CustomMessageDialog";
import { useParams } from "react-router-dom";
import { withStyles } from "@mui/styles";
import CustomDialog from "../../common/CustomDialog";
import Tableoccupied from "../../assets/Tableoccupied.png";
import Noorders from "../../assets/Noorders.png";
import { ReactComponent as WhatsappLogo } from "../../assets/Icons/WhatsappLogo.svg";
import { ReactComponent as RadioButton } from "../../assets/Icons/RadioButton.svg";
import { ReactComponent as ScrollIcon } from "../../assets/Icons/Scroll.svg";
import axios from "axios";
import { URL } from "../../config/config";
import {
  updateTableStatus,
  fetchFloors,
  getClientProfile,
} from "../../redux/floors";
import { connect } from "react-redux";
import { socket } from "../../utils/socket";
import { useNavigate } from "react-router-dom";
import CustomHeader from "../../common/CustomHeader";
import CustomDialogWithButtons from "../../common/CustomDialogWIthButton";

const Header = ({
  tableNo,
  handleOpenDialog,
  status,
  phoneNumber,
  setPhoneNumber,
  sendBill,
  props,
  tableData,
  isFood,
  isAllFoodDone,
}) => {
  return (
    <Box
      display={"flex"}
      alignItems={"center"}
      justifyContent={"space-between"}
    >
      <Box display={"flex"} alignItems={"center"} gap={"16px"}>
        <BackArrow />
        <CustomHeader headerText={`Table ${tableNo}`} />
      </Box>
      {status &&
        (tableData.status_client === "billing" ? (
          <Box display={"flex"} alignItems={"center"} gap={"16px"}>
            <Box display={"flex"} alignItems={"center"}>
              <TextField
                variant="standard"
                value={phoneNumber}
                type="number"
                placeholder="Enter Phone No."
                onChange={(e, value) => setPhoneNumber(e.target.value)}
                style={{
                  border: "none",
                }}
                InputProps={{
                  disableUnderline: true,
                  style: {
                    height: "40.5px",
                    background: "#E6E6E6",
                    border: "none",
                    width: "155px",
                    outline: "none",
                    borderRadius: "0px",
                    borderTopLeftRadius: "4px",
                    borderBottomLeftRadius: "4px",
                    paddingLeft: "12px",
                  },
                }}
              />
              <ButtonWithIcon
                isDisabled={phoneNumber != "" && phoneNumber.length > 9}
                label={"Send Bill"}
                onClick={() =>
                  phoneNumber != "" && phoneNumber.length > 9
                    ? sendBill()
                    : null
                }
                styling={{
                  borderRadius: "0px",
                  borderTopRightRadius: "4px",
                  borderBottomRightRadius: "4px",
                  color: phoneNumber == "" ? "#717171" : "black",
                }}
                icon={
                  <WhatsappLogo
                    style={{
                      width: "24px",
                      height: "24px",
                      marginRight: "4px",
                    }}
                  />
                }
              />
            </Box>
          </Box>
        ) : (
          <Box display={"flex"} alignItems={"center"} gap={"8px"}>
            <ButtonWithIcon
              label={"Add Item"}
              onClick={() => handleOpenDialog()}
              icon={
                <MenuIcon
                  style={{ width: "24px", height: "24px", marginRight: "4px" }}
                />
              }
            />
            <ButtonWithIcon
              isDisabled={isFood && isAllFoodDone}
              label={"Generate Bill"}
              onClick={() => {
                if (isFood && isAllFoodDone) {
                  props.updateTableStatus(
                    tableData.floor_id,
                    tableData._id,
                    "billing"
                  );
                }
              }}
              icon={
                <ScrollIcon
                  style={{ width: "24px", height: "24px", marginRight: "4px" }}
                />
              }
            />
          </Box>
        ))}
    </Box>
  );
};

const FoodTable = (props) => {
  const [dialogOpen, setDialogOpen] = useState(false);
  const [messageDialogOpen, setMessageDialogOpen] = useState(false);
  const [tableData, setTableData] = useState({});
  const [phoneNumber, setPhoneNumber] = useState("");
  const [totalAmount, setTotalAmount] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const { tableNo } = useParams();

  const navigate = useNavigate();

  const handleOpenDialog = () => {
    setDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
  };

  const handleOpenMessageDialog = () => {
    axios
      .post(URL + "clients/send-bill", {
        userNumber: phoneNumber,
        tableId: tableNo,
        floorId: tableData.floor_id,
        currentBookingId: tableData.current_booking_id,
        totalAmount,
      })
      .then((res) => {
        setMessageDialogOpen(true);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  useEffect(() => {
    fetchTableDetails();
    socket.on("order_completed", (data) => {
      fetchTableDetails();
    });
    socket.on("food_ordered", (data) => {
      fetchTableDetails();
    });
    socket.on("table_status_changed", (data) => {
      fetchTableDetails();
    });
    socket.on("bill_sent", (data) => {
      if (data.tableId === tableNo) {
        setMessageDialogOpen(true);
      }
    });
  }, [props.floors]);

  useEffect(() => {
    props.getClientProfile();
    if (!props.floors.length) {
      props.fetchFloors(true);
    }
  }, []);

  const fetchTableDetails = () => {
    console.log("Fetching tables...");
    axios
      .post(URL + "clients/get-table-with-order", { tableId: tableNo })
      .then((res) => {
        const { data } = res.data;
        setTableData(data?.[0] || {});
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const createNewFoodOrder = (addedItems) => {
    handleCloseDialog();
    console.log({ addedItems });
    axios
      .post(URL + "clients/create-food-order", { tableId: tableNo, addedItems })
      .then((res) => {
        fetchTableDetails();
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const changeFoodOrderStatus = (foodOrderId, foodId) => {
    axios
      .post(URL + "clients/change-food-order-status", {
        foodOrderId,
        foodId,
        tableId: tableNo,
      })
      .then((res) => {
        fetchTableDetails();
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const allOrderedFood = (orderedFoods) => {
    let foodOrders = {};
    let calculatedTotalAmount = 0;
    (orderedFoods || []).forEach((value) => {
      value.food_ordered.map((value, index, array) => {
        if (foodOrders[value._id]) {
          foodOrders[value._id].quantity += value.quantity;
          foodOrders[value._id].total_price += value.quantity * value.price;
        } else {
          if (value._id) {
            foodOrders[value._id] = {
              food_name: value.food_name,
              food_type: value.food_type,
              price: value.price,
              quantity: value.quantity,
              total_price: value.price * value.quantity,
            };
          }
        }
      });
    });
    Object.values(foodOrders).forEach((value, index, array) => {
      calculatedTotalAmount += value.total_price;
    });
    return {
      foodOrders,
      totalAmount: calculatedTotalAmount,
    };
  };

  const isAllFoodDone = (orderedFood) => {
    for (var i = 0; i < (orderedFood || []).length; i++) {
      for (var j = 0; j < orderedFood[i].food_ordered.length; j++) {
        if (!orderedFood[i].food_ordered[j].status) {
          return false;
        }
      }
    }
    return true;
  };

  return (
    <React.Fragment>
      <Box sx={{ padding: 6, width: "calc(100% - 360px)", height: "100%" }}>
        <Header
          tableNo={tableData?.table_name}
          handleOpenDialog={() => handleOpenDialog()}
          status={tableData?.status_client != "empty"}
          setPhoneNumber={(value) => {
            setPhoneNumber(value);
          }}
          phoneNumber={phoneNumber}
          sendBill={() => handleOpenMessageDialog()}
          props={props}
          tableData={tableData}
          isFood={tableData?.orderedFood?.length}
          isAllFoodDone={isAllFoodDone(tableData?.orderedFood)}
        />
        {["ordering", "billing"].includes(tableData?.status_client) &&
        tableData?.orderedFood?.length ? (
          <Box
            mt={6}
            style={{
              height: "80%",
              overflow: "auto",
              display: "flex",
              flexDirection: "column",
              gap: "16px",
            }}
          >
            <Box
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "16px",
              }}
            >
              {tableData.status_client === "billing" ? (
                <CustomTable
                  tableData={Object.values(
                    allOrderedFood(tableData.orderedFood).foodOrders
                  )}
                  foodOrderId={""}
                  allDone={true}
                  createdAt={tableData.orderedFood[0].createdAt}
                  updateStatus={changeFoodOrderStatus}
                  isFinal={tableData.status_client === "billing"}
                  setTotalAmount={setTotalAmount}
                />
              ) : (
                tableData.orderedFood.map((value) => {
                  return (
                    <CustomTable
                      tableData={value.food_ordered}
                      foodOrderId={value._id}
                      allDone={
                        !value.food_ordered.find((value) => !value.status)
                      }
                      createdAt={value.createdAt}
                      updateStatus={changeFoodOrderStatus}
                      isFinal={tableData.status_client === "billing"}
                    />
                  );
                })
              )}
            </Box>
          </Box>
        ) : (
          <Box
            style={{
              width: "100%",
              height: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
              gap: "16px",
            }}
          >
            <img
              src={
                tableData.status_client === "empty" ? Tableoccupied : Noorders
              }
              style={{ width: "350px" }}
            />
            {tableData.status_client === "empty" && (
              <ButtonWithIcon
                isDisabled={!isLoading}
                onClick={async () => {
                  setIsLoading(true);
                  await props.updateTableStatus(
                    tableData.floor_id,
                    tableData._id,
                    "occupied"
                  );
                  setIsLoading(false);
                }}
                icon={
                  <RadioButton style={{ marginRight: "4px", width: "24px" }} />
                }
                isLoading={isLoading}
                label={"Set status to occupied"}
              />
            )}
          </Box>
        )}
      </Box>
      <CustomDialog
        open={dialogOpen}
        handleClose={handleCloseDialog}
        clientId={tableData.client_id}
        onSubmit={(addedItems) => createNewFoodOrder(addedItems)}
      />

      <CustomDialogWithButtons
        open={messageDialogOpen}
        handleClose={() => {
          setMessageDialogOpen(false);
          navigate("/tables");
        }}
        iconImage={<WhatsappLogo strokeWidth={0} width={32} height={32} />}
        message={`The Bill of ₹ ${totalAmount} was successfully sent on WhatsApp
        to the phone number ${phoneNumber}.`}
        title={"Bill Sent Successfully"}
      />
    </React.Fragment>
  );
};

const useStyles = (theme) => ({
  root: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "flex-end",
    height: "100%",
  },
});
const connectedFoodTable = connect(
  (state) => ({
    floors: state.floors.orderingFloors,
  }),
  {
    updateTableStatus,
    fetchFloors,
    getClientProfile,
  }
)(FoodTable);

export default withStyles(useStyles)(connectedFoodTable);
