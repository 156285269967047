import React, { useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { Box, Typography } from "@mui/material";
import ButtonWithIcon from "./ButtonWithIcon";
import QuantitySelector from "./QuantitySelector";
import CustomFloorDialogTable from "./CustomFloorDialogTable";
import CustomHeader from "./CustomHeader";

const CustomFloorCreationDialog = ({
  open,
  handleClose,
  setFloors,
  floors,
}) => {
  const [floorName, setFloorName] = useState(floors?.floor_name || "");
  const [floorTable, setFloorTable] = useState(floors?.tables || []);
  const [deletedFloorTable, setDeletedFloorTable] = useState(
    floors?.tables || []
  );

  const updateFloorTable = (index, key, value) => {
    if (key) {
      floorTable[index][key] = value;
      setFloorTable(floorTable);
    } else {
      floorTable[index]["isDeleted"] = true;
      setDeletedFloorTable(floorTable.find((value) => value._id === index));
      setFloorTable(floorTable.filter((value) => value._id !== index));
    }
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth="md"
      style={{ display: "flex", justifyContent: "end" }}
      PaperProps={{
        style: {
          height: "100%",
          maxHeight: "100%",
          borderRadius: "0px",
          margin: "0px",
        },
      }}
    >
      <DialogTitle
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <CustomHeader
          headerText={floors?._id ? floorName : "Create Floor & Add Tables"}
        />

        <Box>
          <ButtonWithIcon
            label={"Save"}
            onClick={() =>
              setFloors({
                _id: floors?._id,
                floor_name: floorName,
                num_of_table: floorTable.length,
                tables: floorTable,
                deletedTabled: deletedFloorTable,
              })
            }
          />
          <IconButton
            edge="end"
            color="inherit"
            onClick={handleClose}
            aria-label="close"
            style={{ marginLeft: "20px" }}
          >
            <CloseIcon
              sx={{
                fontSize: "20px",
              }}
            />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent
        style={{
          backgroundColor: "white",
          height: "100%",
          marginTop: "40px",
          width: "400px",
        }}
      >
        <Box
          style={{
            width: "90%",
            display: "flex",
            gap: "20px",
          }}
        >
          <Box
            style={{
              width: "60%",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Typography fontFamily={"Manrope"} variant="p" fontWeight="500">
              Floor Name
            </Typography>
            <TextField
              placeholder="Floor name"
              value={floorName}
              onChange={(e) => setFloorName(e.target.value)}
              style={{
                borderRadius: "8px",
                background: "#E6E6E6",
                width: "100%",
                marginTop: "8px",
              }}
            />
          </Box>
          <Box
            style={{
              width: "40%",
              display: "flex",
              justifyContent: "space-between",
              flexDirection: "column",
              alignItems: "start",
            }}
          >
            <Typography fontFamily={"Manrope"} variant="p" fontWeight="500">
              No. of Tables
            </Typography>
            <QuantitySelector
              value={floorTable.filter((value) => !value.isDeleted).length}
              onIncrement={() => {
                setFloorTable([
                  ...floorTable,
                  {
                    id: Date.now(),
                    status: true,
                    table_name:
                      floorTable.length > 8
                        ? floorTable.length + 1
                        : `0${floorTable.length + 1}`,
                  },
                ]);
              }}
              onDecrement={(value) => {
                setFloorTable(floorTable.slice(0, -1));
              }}
            />
          </Box>
        </Box>
        <Box
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            marginTop: "20px",
          }}
        >
          <Typography fontFamily={"Manrope"} variant="p" fontWeight="500">
            Item List
          </Typography>
          <CustomFloorDialogTable
            floorTable={floorTable}
            updateFloorTable={updateFloorTable}
          />
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default CustomFloorCreationDialog;
