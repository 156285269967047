import React, { useEffect, useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { Autocomplete, Box, InputAdornment, Typography } from "@mui/material";
import { Search } from "@mui/icons-material";
import CustomDialogTable from "./CustomDialogTable";
import ButtonWithIcon from "./ButtonWithIcon";
import { URL } from "../config/config";
import axios from "axios";
import CustomHeader from "./CustomHeader";

const CustomDialog = ({ open, handleClose, clientId, onSubmit }) => {
  const [option, setOption] = useState(null);
  const [items, setItems] = useState([]);
  const [addedItems, setAddedItems] = useState([]);
  const handleFormSubmit = (addedItems) => {
    setAddedItems([]);
    setOption(null);
    onSubmit(addedItems);
  };

  useEffect(() => {
    if (clientId) {
      axios
        .post(URL + "clients/get-all-menu-food", { clientId })
        .then((res) => {
          const { data } = res.data;
          setItems(data);
        })
        .catch((err) => {
          console.error(err);
        });
    }
  }, [clientId]);

  const handleIncrement = (id) => {
    const newItems = addedItems.map((item) =>
      item._id === id
        ? {
            ...item,
            quantity: item.quantity + 1,
            total_price: item.price * (item.quantity + 1),
          }
        : item
    );
    setAddedItems(newItems);
  };
  const handleDecrement = (id) => {
    const newItems = addedItems
      .map((item) =>
        item._id === id
          ? {
              ...item,
              quantity: Math.max(item.quantity - 1, 0),
              total_price: item.price * Math.max(item.quantity - 1, 0),
            }
          : item
      )
      .filter((item) => item.quantity);

    setAddedItems(newItems);
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth="md"
      style={{ display: "flex", justifyContent: "end" }}
      PaperProps={{
        style: {
          height: "100%",
          maxHeight: "100%",
          borderRadius: "0px",
          margin: "0px",
        },
      }}
    >
      <DialogTitle
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <CustomHeader headerText={"Add Food Item"} />

        <Box display={"flex"}>
          <Box
            style={{
              display: "flex",
              justifyContent: "flex-end",
              width: "90%",
              marginTop: "16px",
            }}
          >
            <ButtonWithIcon
              isDisabled={addedItems.length}
              onClick={() => handleFormSubmit(addedItems)}
              label={"Add"}
            />
          </Box>
          <IconButton
            disableRipple
            edge="end"
            color="inherit"
            onClick={handleClose}
            aria-label="close"
            style={{ marginLeft: "20px" }}
          >
            <CloseIcon
              sx={{
                fontSize: "20px",
              }}
            />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent
        style={{
          backgroundColor: "white",
          height: "100%",
          marginTop: "40px",
          width: "500px",
        }}
      >
        <Box
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Typography fontFamily={"Manrope"} variant="p" fontWeight="500">
            Food Item
          </Typography>
          <Box display={"flex"} width={"90%"} alignItems={"center"} gap={"8px"}>
            <Autocomplete
              disablePortal
              fullWidth
              id="combo-box-demo"
              options={items}
              value={option}
              onChange={(e, v) => setOption(v)}
              onSelect={(e) => {
                if (option) {
                  let itm = addedItems;
                  let isAdd = true;
                  itm = itm.map((value) => {
                    if (value._id === option._id) {
                      isAdd = false;
                      value.quantity += 1;
                    }
                  });

                  setAddedItems([
                    ...addedItems,
                    ...(isAdd
                      ? [
                          {
                            _id: option._id,
                            food_type: option?.food_type,
                            food_name: option.food_name,
                            food_description: option.food_description,
                            total_price: option.price,
                            price: option.price,
                            status: false,
                            quantity: 1,
                          },
                        ]
                      : []),
                  ]);
                  setOption(null);
                }
              }}
              onClick={(e, v) => console.log({ e, v })}
              getOptionLabel={(option) => option.food_name}
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder="Search and Add Items"
                  style={{
                    borderRadius: "8px",
                    background: "#E6E6E6",
                    marginTop: "8px",
                  }}
                  InputProps={{
                    ...params.InputProps,
                    startAdornment: (
                      <InputAdornment position="start">
                        <Search />
                      </InputAdornment>
                    ),
                  }}
                />
              )}
            />
          </Box>
        </Box>
        <Box
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            marginTop: "20px",
          }}
        >
          <Typography fontFamily={"Manrope"} variant="p" fontWeight="500">
            Item List
          </Typography>
          <CustomDialogTable
            addedItems={addedItems}
            handleDecrement={handleDecrement}
            handleIncrement={handleIncrement}
          />
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default CustomDialog;
