import { io } from "socket.io-client";
import { URL } from "../config/config";

export const socket = io(URL, {
  path: "/socket",
  transports: ["websocket"],
});

// Listen for 'connect' event
socket.on("connect", () => {
  console.log("socket connected");
});
