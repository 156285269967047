import React, { useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import {
  Autocomplete,
  Box,
  Button,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import { ReactComponent as Article } from "../assets/Icons/Article.svg";
import { ReactComponent as ListDashes } from "../assets/Icons/ListDashes.svg";
import { ReactComponent as ForkKnife } from "../assets/Icons/ForkKnife.svg";
import { ReactComponent as CurrencyInr } from "../assets/Icons/CurrencyInr.svg";
import ButtonWithIcon from "./ButtonWithIcon";
import CustomHeader from "./CustomHeader";

const CustomAddMenuItemDialog = ({
  open,
  handleClose,
  categories,
  onSave,
  editFoodItem,
}) => {
  const [foodItem, setFoodItem] = useState(
    editFoodItem || {
      food_name: "",
      food_description: "",
      price: "",
      food_type: "",
      food_category: "",
    }
  );

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth="md"
      style={{ display: "flex", justifyContent: "end" }}
      PaperProps={{
        style: {
          height: "100%",
          maxHeight: "100%",
          borderRadius: "0px",
          margin: "0px",
        },
      }}
    >
      <DialogTitle
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <CustomHeader headerText={"Add Menu Item"} />

        <Box>
          <ButtonWithIcon
            label={"Save"}
            isDisabled={
              foodItem.food_name &&
              foodItem.price &&
              foodItem.food_type &&
              foodItem.food_category
            }
            onClick={() => {
              if (
                foodItem.food_name &&
                foodItem.price &&
                foodItem.food_type &&
                foodItem.food_category
              ) {
                onSave({
                  ...foodItem,
                });
                setFoodItem({
                  food_name: "",
                  food_description: "",
                  price: "",
                  food_type: "",
                  food_category: "",
                });
              }
            }}
          />
          <IconButton
            edge="end"
            color="inherit"
            onClick={handleClose}
            aria-label="close"
            style={{ marginLeft: "20px" }}
          >
            <CloseIcon
              sx={{
                fontSize: "20px",
              }}
            />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent
        style={{
          backgroundColor: "white",
          height: "100%",
          marginTop: "40px",
          width: "400px",
        }}
      >
        <Box
          style={{
            width: "90%",
            display: "flex",
            flexDirection: "column",
            gap: "20px",
          }}
        >
          <Box
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "8px",
            }}
          >
            <Typography fontFamily={"Manrope"} variant="p" fontWeight="500">
              Category
            </Typography>
            <Autocomplete
              disablePortal
              fullWidth
              freeSolo
              id="combo-box-demo"
              options={categories}
              value={foodItem.food_category}
              onChange={(e, value) =>
                setFoodItem({
                  ...foodItem,
                  food_category: value,
                })
              }
              placeholder="Enter or select category"
              renderInput={(params) => (
                <TextField
                  {...params}
                  onChange={(e) =>
                    setFoodItem({
                      ...foodItem,
                      food_category: e.target.value,
                    })
                  }
                  placeholder="Enter or Select Category"
                  style={{
                    borderRadius: "8px",
                    background: "#E6E6E6",
                  }}
                  InputProps={{
                    ...params.InputProps,
                    startAdornment: (
                      <InputAdornment position="start">
                        <ListDashes style={{ width: "20px" }} />
                      </InputAdornment>
                    ),
                  }}
                />
              )}
            />
          </Box>
          <Box
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "8px",
            }}
          >
            <Typography fontFamily={"Manrope"} variant="p" fontWeight="500">
              Item Name
            </Typography>
            <TextField
              placeholder="Enter name"
              value={foodItem.food_name}
              onChange={(e) =>
                setFoodItem({ ...foodItem, food_name: e.target.value })
              }
              style={{
                borderRadius: "8px",
                background: "#E6E6E6",
                width: "100%",
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <ForkKnife style={{ width: "20px" }} />
                  </InputAdornment>
                ),
              }}
            />
          </Box>
          <Box
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "8px",
            }}
          >
            <Typography fontFamily={"Manrope"} variant="p" fontWeight="500">
              Description
            </Typography>
            <TextField
              placeholder="Enter description"
              value={foodItem.food_description}
              onChange={(e) =>
                setFoodItem({ ...foodItem, food_description: e.target.value })
              }
              style={{
                borderRadius: "8px",
                background: "#E6E6E6",
                width: "100%",
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Article style={{ width: "20px" }} />
                  </InputAdornment>
                ),
              }}
            />
          </Box>
          <Box
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "8px",
            }}
          >
            <Typography fontFamily={"Manrope"} variant="p" fontWeight="500">
              Price
            </Typography>
            <TextField
              placeholder="Enter price"
              value={foodItem.price}
              onChange={(e) =>
                setFoodItem({ ...foodItem, price: e.target.value })
              }
              style={{
                borderRadius: "8px",
                background: "#E6E6E6",
                width: "100%",
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <CurrencyInr style={{ width: "20px" }} />
                  </InputAdornment>
                ),
              }}
            />
          </Box>
          <Box
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "8px",
            }}
          >
            <Typography fontFamily={"Manrope"} variant="p" fontWeight="500">
              Select Type
            </Typography>
            <Box display={"flex"} justifyContent={"space-between"}>
              {[
                { label: "Veg", background: "#2D7D4D" },
                { label: "Egg", background: "#ECB800" },
                { label: "Non-Veg", background: "#B82627" },
              ].map((value) => {
                return (
                  <Box
                    display={"flex"}
                    alignItems={"center"}
                    gap={"8px"}
                    onClick={() =>
                      setFoodItem({ ...foodItem, food_type: value.label })
                    }
                    style={{
                      padding: "14px",
                      border: `1px solid ${
                        foodItem.food_type === value.label
                          ? "transparent"
                          : "#E6E6E6"
                      }`,
                      borderRadius: "8px",
                      cursor: "pointer",
                      background:
                        foodItem.food_type === value.label
                          ? `${value.background}20`
                          : "transparent",
                    }}
                  >
                    <Box
                      style={{
                        border: `2px solid ${value.background}`,
                        padding: "6px",
                        borderRadius: "4px",
                      }}
                    >
                      <Box
                        style={{
                          width: "12px",
                          height: "12px",
                          borderRadius: "50%",
                          background: value.background,
                        }}
                      />
                    </Box>
                    <Typography fontFamily={"Manrope"}>
                      {value.label}
                    </Typography>
                  </Box>
                );
              })}
            </Box>
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default CustomAddMenuItemDialog;
