import {
  Autocomplete,
  Box,
  Grid,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import Hamburger from "../../assets/Hamburger.png";
import { ReactComponent as MenuIcon } from "../../assets/Icons/Menu.svg";
import { ReactComponent as FileArrowUp } from "../../assets/Icons/FileArrowUp.svg";
import { ReactComponent as ListDashes } from "../../assets/Icons/ListDashes.svg";
import { ReactComponent as Pizza_slice } from "../../assets/Icons/Pizza_slice.svg";
import { ReactComponent as MagnifyingGlass } from "../../assets/Icons/MagnifyingGlass.svg";
import ButtonWithIcon from "../../common/ButtonWithIcon";
import CustomMenuCreationDialog from "../../common/CustomMenuDialog";
import CustomAddMenuItemDialog from "../../common/CustomAddMenuItemDialog";
import CustomMenuDialogTable from "../../common/CustomMenuDialogTable";
import { URL } from "../../config/config";
import axios from "axios";
import CustomHeader from "../../common/CustomHeader";
import CustomDialogWithButtons from "../../common/CustomDialogWIthButton";

const tableHeads = [
  { label: "S.No.", key: "" },
  { label: "Food Item", key: "food_name" },
  { label: "Description", key: "food_description" },
  { label: "Category", key: "food_category" },
  { label: "Price", key: "price" },
  "",
];

const Header = ({ setAddMenuItemDialog, foodItems }) => {
  return (
    <Box
      display={"flex"}
      alignItems={"center"}
      justifyContent={"space-between"}
    >
      <CustomHeader headerText={"Menu"} />
      {foodItems && (
        <Box display={"flex"} gap={"16px"}>
          <ButtonWithIcon
            onClick={() => setAddMenuItemDialog(true)}
            icon={
              <MenuIcon
                style={{ marginRight: "4px", width: "24px", color: "black" }}
              />
            }
            label={"Add New Item"}
          />
        </Box>
      )}
    </Box>
  );
};

const FoodMenu = () => {
  const [menuCreationDialog, setMenuCreationDialog] = useState(false);
  const [addMenuItemDialog, setAddMenuItemDialog] = useState(false);
  const [categories, setCategories] = useState([]);
  const [foodItems, setFoodItems] = useState([]);
  const [editFoodItem, setEditFoodItem] = useState(null);
  const [dialog, setDialog] = useState(false);
  const [updateDialog, setUpdateDialog] = useState(false);

  const [filter, setFilter] = useState({
    name: "",
    category: "",
  });

  useEffect(() => {
    // fetchFoodCategory();
    fetchFoodItems();
  }, []);

  useEffect(() => {
    let ctgries = [];
    foodItems.forEach((foodItem) => {
      if (!ctgries.includes(foodItem.food_category)) {
        ctgries.push(foodItem.food_category);
      }
    });
    setCategories(ctgries);
  }, [foodItems]);

  // async function fetchFoodCategory() {
  //   await axios.get(URL + "static/get-food-menu-categories").then((value) => {
  //     setCategories(value.data.data);
  //   });
  // }

  async function fetchFoodItems() {
    await axios
      .post(URL + "clients/get-all-menu-food")
      .then((value) => {
        setFoodItems(value.data.data);
      })
      .catch((err) => {
        console.log({ err });
      });
  }

  async function addFoodItem(foodItem) {
    await axios.post(URL + "clients/add-menu-food", foodItem).then((value) => {
      fetchFoodItems();
      setAddMenuItemDialog(false);
      setDialog(true);
    });
  }

  async function addBulkFoodItems(foodItems) {
    await axios
      .post(URL + "clients/add-bulk-menu-food", foodItems)
      .then((value) => {
        fetchFoodItems();
        setAddMenuItemDialog(false);
        setDialog(true);
      })
      .then((value) => {
        fetchFoodItems();
      })
      .catch((err) => {
        console.error({ err });
      });
  }

  async function updateItemsStatus(index, status) {
    foodItems[index].status = status;
    await updateItems(foodItems[index]);
    setFoodItems(foodItems);
  }

  async function updateItems(foodItem) {
    await axios
      .post(URL + "clients/update-menu-food", foodItem)
      .then((value) => {
        fetchFoodItems();
        setAddMenuItemDialog(false);
        setUpdateDialog(true);
      });
  }

  return (
    <Box sx={{ padding: 6, width: "calc(100% - 360px)" }}>
      <Header
        setAddMenuItemDialog={setAddMenuItemDialog}
        setMenuCreationDialog={setMenuCreationDialog}
        foodItems={foodItems.length}
      />
      {foodItems.length ? (
        <Box mt={8}>
          <Grid display={"flex"} gap={"24px"}>
            <Box display={"flex"} flexDirection={"column"}>
              <Typography variant="p" fontWeight="500">
                Category
              </Typography>
              <Autocomplete
                disablePortal
                style={{ width: "250px" }}
                id="combo-box-demo"
                options={["All", ...categories]}
                value={filter?.category || ""}
                placeholder="Select Category"
                onChange={(e, value) =>
                  setFilter({ ...filter, category: value })
                }
                getOptionLabel={(option) => option || ""}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder="Select Category"
                    style={{
                      borderRadius: "8px",
                      background: "#E6E6E6",
                      marginTop: "8px",
                    }}
                    InputProps={{
                      ...params.InputProps,
                      style: {
                        padding: "8px 16px",
                      },
                      startAdornment: (
                        <InputAdornment position="start">
                          <ListDashes strokeWidth={0} width={16} height={16} />
                        </InputAdornment>
                      ),
                    }}
                  />
                )}
              />
            </Box>
            <Box display={"flex"} flexDirection={"column"}>
              <Typography variant="p" fontWeight="500">
                Search Food Item
              </Typography>
              <TextField
                placeholder="Search"
                onChange={(e) => setFilter({ ...filter, name: e.target.value })}
                value={filter?.name || ""}
                style={{
                  borderRadius: "8px",
                  background: "#E6E6E6",
                  width: "250px",
                  marginTop: "8px",
                  style: {
                    padding: "8px 16px",
                  },
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <MagnifyingGlass strokeWidth={0} width={20} height={20} />
                    </InputAdornment>
                  ),
                }}
              />
            </Box>
          </Grid>
          <CustomMenuDialogTable
            tableHeads={tableHeads}
            tableItems={foodItems}
            categories={categories}
            filter={filter}
            updateItems={updateItemsStatus}
            key={Date.now()}
            onEdit={(foodItem) => {
              setEditFoodItem(foodItem);
              setAddMenuItemDialog(true);
            }}
          />
        </Box>
      ) : (
        <Box
          style={{
            width: "100%",
            height: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
            gap: "16px",
          }}
        >
          <img src={Hamburger} style={{ width: "350px" }} />
          <Box display={"flex"} gap={"8px"}>
            <ButtonWithIcon
              onClick={() => setMenuCreationDialog(true)}
              icon={
                <FileArrowUp style={{ marginRight: "4px", width: "24px" }} />
              }
              label={"Import Menu"}
            />
            <ButtonWithIcon
              onClick={() => setAddMenuItemDialog(true)}
              icon={<MenuIcon style={{ marginRight: "4px", width: "24px" }} />}
              label={"Add Manually"}
            />
          </Box>
        </Box>
      )}
      <CustomMenuCreationDialog
        open={menuCreationDialog}
        handleClose={() => setMenuCreationDialog(false)}
        onSave={async (foodItems) => {
          setMenuCreationDialog(false);
          await addBulkFoodItems(foodItems);
        }}
      />
      <CustomAddMenuItemDialog
        open={addMenuItemDialog}
        handleClose={() => setAddMenuItemDialog(false)}
        categories={categories}
        onSave={(foodItem) => {
          if (foodItem._id) {
            updateItems(foodItem);
          } else {
            addFoodItem(foodItem);
          }
        }}
        key={Date.now()}
        editFoodItem={editFoodItem}
      />
      <CustomDialogWithButtons
        open={dialog}
        handleClose={() => setDialog(false)}
        iconImage={<MenuIcon strokeWidth={0} width={32} height={32} />}
        message={
          "The new menu has been successfully added and is now ready for use."
        }
        title={"Menu Added"}
      />
      <CustomDialogWithButtons
        open={updateDialog}
        handleClose={() => setUpdateDialog(false)}
        iconImage={<Pizza_slice strokeWidth={0} width={32} height={32} />}
        message={
          "The food item information updates have been successfully applied."
        }
        title={"Changes Updated"}
      />
    </Box>
  );
};

export default FoodMenu;
