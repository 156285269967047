import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";

const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
  border: "1px solid black",
  borderRadius: "30px",
  overflow: "hidden",
  "& .MuiToggleButtonGroup-grouped": {
    width: "150px",
    margin: theme.spacing(0.5),
    border: 0,
    "&.Mui-selected": {
      backgroundColor: "black",
      color: "white",
      "&:hover": {
        backgroundColor: "black",
      },
    },
    "&:not(:first-of-type)": {
      borderRadius: "30px",
    },
    "&:first-of-type": {
      borderRadius: "30px",
    },
  },
}));

export default function CustomizedDividers({ onChange }) {
  const [alignment, setAlignment] = useState("all");

  const handleAlignment = (event, newAlignment) => {
    if (newAlignment !== null) {
      setAlignment(newAlignment);
      onChange(newAlignment);
    }
  };

  return (
    <StyledToggleButtonGroup
      value={alignment}
      exclusive
      onChange={handleAlignment}
      aria-label="text alignment"
    >
      <ToggleButton value="all" aria-label="left aligned">
        All
      </ToggleButton>
      <ToggleButton value="week" aria-label="centered">
        This Week
      </ToggleButton>
      <ToggleButton value="month" aria-label="right aligned">
        This Month
      </ToggleButton>
    </StyledToggleButtonGroup>
  );
}
