import React, { useState } from "react";
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TextField,
  Box,
} from "@mui/material";
import { ReactComponent as Trash } from "../assets/Icons/Trash.svg";
import CustomSwitch from "./CustomSwitch";

const StyledTableCell = ({ align, children }) => (
  <TableCell align={align} style={{ padding: 8 }}>
    {children}
  </TableCell>
);

const CustomFloorDialogTable = ({ floorTable, updateFloorTable }) => {
  const [update, setUpdate] = useState(false);

  function updateData(index, key, value) {
    updateFloorTable(index, key, value);
    setUpdate(!update);
  }
  let i = 0;

  return (
    <TableContainer
      style={{
        width: "90%",
        height: "400px",
        marginTop: "16px",
        background: "#FAF8F4",
        borderRadius: "8px",
      }}
    >
      <Table>
        <TableHead
          style={{
            background: "#E1DFDC",
            position: "sticky",
            top: 0,
            zIndex: 1,
          }}
        >
          <TableRow>
            <StyledTableCell align="center">S.No.</StyledTableCell>
            <StyledTableCell align="center">Items</StyledTableCell>
            <StyledTableCell align="center"></StyledTableCell>
            <StyledTableCell align="center"></StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {floorTable.map((row, index) => {
            if (!row.isDeleted) {
              i += 1;
            }
            return row.isDeleted ? null : (
              <TableRow key={row.id}>
                <StyledTableCell align="center">{i}.</StyledTableCell>
                <StyledTableCell align="center">
                  <TextField
                    name="table_name"
                    value={row.table_name}
                    onChange={(e) =>
                      updateData(index, "table_name", e.target.value)
                    }
                    style={{
                      borderRadius: "8px",
                      background: "#E6E6E6",
                      width: "65px",
                    }}
                  />
                </StyledTableCell>
                <StyledTableCell align="center">
                  <CustomSwitch
                    status={row.status}
                    onClick={(status) => updateData(index, "status", status)}
                  />
                </StyledTableCell>
                <StyledTableCell align="center">
                  <Trash
                    style={{ cursor: "pointer" }}
                    onClick={() => updateData(index)}
                  />
                </StyledTableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default CustomFloorDialogTable;
