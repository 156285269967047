import PropTypes from "prop-types";
import React from "react";
import styled from "styled-components";

const StyledFoodType = styled.div`
  border: 2px solid;
  border-radius: 5px;
  height: 32px;
  width: 32px;

  & .ellipse {
    left: 7px;
    position: relative;
    width: 14px;
  }

  & .polygon {
    height: 13px;
    left: 7px;
    position: absolute;
    top: 7px;
    width: 14px;
  }

  &.egg {
    border-color: #ecb800;
  }

  &.veg {
    border-color: #2d7d4d;
  }

  &.non-veg {
    border-color: #b82627;
    position: relative;
  }

  &.egg .ellipse {
    background-color: #ecb800;
    border-radius: 7px/8px;
    height: 16px;
    top: 6px;
  }

  &.veg .ellipse {
    background-color: #2d7d4d;
    border-radius: 7px;
    height: 14px;
    top: 7px;
  }

  &.non-veg .ellipse {
    background-color: #2d7d4d;
    border-radius: 7px;
    height: 14px;
    top: 7px;
  }
`;

export const FoodType = ({
  property1,
  className,
  ellipseClassName,
  polygon = "https://c.animaapp.com/qmMdcQ0L/img/polygon-1.svg"
}) => {
  return (
    <StyledFoodType className={`food-type ${property1} ${className}`}>
      {["egg", "veg"].includes(property1) && <div className={`ellipse ${ellipseClassName}`} />}

      {property1 === "non-veg" && <img className={`polygon ${ellipseClassName}`} alt="Polygon" src={polygon} />}
    </StyledFoodType>
  );
};

FoodType.propTypes = {
  property1: PropTypes.oneOf(["veg", "egg", "non-veg"]),
  polygon: PropTypes.string
};
