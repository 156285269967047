import { createStore, applyMiddleware } from "redux";
import { thunk } from "redux-thunk";
import rootReducer from "./rootReducer";
import { batchedSubscribe } from "redux-batched-subscribe";
import { debounce } from "@mui/material";
import { compose } from "redux";

const debounceNotify = debounce((notify) => notify(), 100);

const store = createStore(
  rootReducer,
  compose(applyMiddleware(thunk), batchedSubscribe(debounceNotify))
);

export default store;
