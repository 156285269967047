import React, { useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import {
  Autocomplete,
  Box,
  Button,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import { ReactComponent as UserCircle } from "../assets/Icons/UserCircle.svg";
import { ReactComponent as Phone } from "../assets/Icons/Phone.svg";
import { ReactComponent as Lock } from "../assets/Icons/Lock.svg";
import ButtonWithIcon from "./ButtonWithIcon";
import CustomHeader from "./CustomHeader";

const CustomAddWaiterDialog = ({ open, handleClose, onSave, editWaiter }) => {
  const [waiterName, setWaiterName] = useState(editWaiter?.waiter_name || "");
  const [phoneNumber, setPhoneNumber] = useState(
    editWaiter?.phone_number || ""
  );
  const [password, setPassword] = useState(editWaiter?.password || "");

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth="md"
      style={{ display: "flex", justifyContent: "end" }}
      PaperProps={{
        style: {
          height: "100%",
          maxHeight: "100%",
          borderRadius: "0px",
          margin: "0px",
        },
      }}
    >
      <DialogTitle
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <CustomHeader headerText={"Add Waiter"} />

        <Box>
          <ButtonWithIcon
            label={"Save"}
            onClick={() => {
              onSave({
                ...editWaiter,
                waiter_name: waiterName,
                password,
                phone_number: phoneNumber,
              });
            }}
          />
          <IconButton
            edge="end"
            color="inherit"
            onClick={handleClose}
            aria-label="close"
            style={{ marginLeft: "20px" }}
          >
            <CloseIcon
              sx={{
                fontSize: "20px",
              }}
            />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent
        style={{
          backgroundColor: "white",
          height: "100%",
          marginTop: "40px",
          width: "400px",
        }}
      >
        <Box
          style={{
            width: "90%",
            display: "flex",
            flexDirection: "column",
            gap: "20px",
          }}
        >
          <Box
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "8px",
            }}
          >
            <Typography  fontFamily={'Manrope'} variant="p" fontWeight="500">
              Waiter Name
            </Typography>
            <TextField
              placeholder="Enter Name"
              onChange={(e) => setWaiterName(e.target.value)}
              value={waiterName}
              style={{
                borderRadius: "8px",
                background: "#E6E6E6",
                width: "100%",
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <UserCircle style={{ width: "20px" }} />
                  </InputAdornment>
                ),
              }}
            />
          </Box>
          <Box
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "8px",
            }}
          >
            <Typography fontFamily={'Manrope'} variant="p" fontWeight="500">
              Waiter Phone No.
            </Typography>
            <TextField
              placeholder="Enter Phone No."
              onChange={(e) => setPhoneNumber(e.target.value)}
              value={phoneNumber}
              style={{
                borderRadius: "8px",
                background: "#E6E6E6",
                width: "100%",
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Phone style={{ width: "20px" }} />
                  </InputAdornment>
                ),
              }}
            />
          </Box>
          <Box
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "8px",
            }}
          >
            <Typography fontFamily={'Manrope'} variant="p" fontWeight="500">
              Create Password
            </Typography>
            <TextField
              placeholder="Create Password"
              onChange={(e) => setPassword(e.target.value)}
              value={password}
              style={{
                borderRadius: "8px",
                background: "#E6E6E6",
                width: "100%",
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Lock style={{ width: "20px" }} />
                  </InputAdornment>
                ),
              }}
            />
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default CustomAddWaiterDialog;
