import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import CustomButton from "./CustomButton";
import { withStyles } from "@mui/styles";
import moment from "moment";

function getFormattedDate(date) {
  const d = new Date(date);
  return date ? moment(d).format("DD-MM-yyyy") : "";
}

function getFormattedTime(date) {
  const d = new Date(date);
  return date ? moment(d).format("hh:mm A") : "";
}

function CustomBillingTable(props) {
  const classes = props.classes;
  return (
    <TableContainer
      component={Paper}
      style={{
        borderRadius: "8px",
        boxShadow: "none",
        background: "#FAF8F4",
        overflowY: "auto",
        height: "90%",
      }}
    >
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead
          style={{
            background: "#E1DFDC",
            position: "sticky",
            top: 0,
            zIndex: 1,
          }}
        >
          <TableRow>
            <TableCell className={classes.tableCell}>S No.</TableCell>
            <TableCell className={classes.tableCell}>Customer No.</TableCell>
            <TableCell className={classes.tableCell}>Floor</TableCell>
            <TableCell className={classes.tableCell}>Table No.</TableCell>
            <TableCell className={classes.tableCell}>Total Bill</TableCell>
            <TableCell className={classes.tableCell}>Date</TableCell>
            <TableCell className={classes.tableCell}>Time</TableCell>
            <TableCell className={classes.tableCell}></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.tableData.map((billingData, index) => {
            return (
              <TableRow
                key={index}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell className={classes.tableCell}>
                  {index > 9 ? "" : 0}
                  {index + 1}
                </TableCell>
                <TableCell>{billingData.user_number}</TableCell>
                <TableCell>{billingData.floor.floor_name}</TableCell>
                <TableCell>{billingData.tables.table_name}</TableCell>
                <TableCell className={classes.tableCell}>
                  ₹ {billingData.total_billing_amount}
                </TableCell>
                <TableCell className={classes.tableCell}>
                  {getFormattedDate(billingData.createdAt)}
                </TableCell>
                <TableCell className={classes.tableCell}>
                  {getFormattedTime(billingData.createdAt)}
                </TableCell>
                <TableCell className={classes.tableCell} style={{ flex: 1 }}>
                  <CustomButton
                    onClick={() =>
                      (window.location = `/billing/final-billing/${billingData._id}`)
                    }
                    label={"View Details"}
                  />
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

const useStyles = (theme) => ({
  tableCell: {
    textAlign: "left",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    width: "130px",
    fontFamily: "Manrope",
  },
  tableNameCell: {
    display: "flex",
    alignItems: "center",
    justifyContent: "start",
    gap: "8px",
  },
});

export default withStyles(useStyles)(CustomBillingTable);
