import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import { Box, Button, Typography } from "@mui/material";
import Logo from "../assets/Logo.png";
import { ReactComponent as Check } from "../assets/Icons/Check.svg";
import { ReactComponent as Cross } from "../assets/Icons/X.svg";

const CustomDialogWithButtons = ({
  open,
  handleClose,
  message,
  title,
  iconImage,
  isDelete,
  onDelete,
}) => {
  return (
    <Dialog
      key={Date.now()}
      open={open}
      onClose={handleClose}
      maxWidth="md"
      style={{
        display: "flex",
        justifyContent: "center",
      }}
      PaperProps={{
        style: {
          borderRadius: "16px",
        },
      }}
    >
      <DialogContent
        style={{
          backgroundColor: "white",
          maxWidth: "325px",
        }}
      >
        {/* <Box
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "end",
          }}
        >
          <Cross
            style={{
              cursor: "pointer",
            }}
            onClick={() => handleClose()}
            strokeWidth={0}
            width={18}
            height={18}
          />
        </Box> */}
        <Box
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: "8px",
          }}
        >
          {iconImage}
          {title && (
            <Typography variant="h6" fontWeight="600" textAlign={"center"}>
              {title}
            </Typography>
          )}
          <Typography
            fontFamily={"Manrope"}
            variant="p"
            fontWeight="500"
            textAlign={"center"}
          >
            {message}
          </Typography>
        </Box>
      </DialogContent>
      {isDelete ? (
        <Box
          style={{
            display: "flex",
          }}
        >
          <Button
            disableRipple
            sx={{
              borderRadius: "0px",
              width: "100%",
              background: "#1D2020",
              color: "white",
              textTransform: "capitalize",
              padding: "8px",
              cursor: "pointer",
              "&:hover": {
                backgroundColor: "#1D2020",
              },
            }}
            onClick={() => handleClose()}
          >
            Cancel
          </Button>
          <Button
            disableRipple
            sx={{
              borderRadius: "0px",
              width: "100%",
              background: "#DBDD26",
              color: "black",
              textTransform: "capitalize",
              padding: "8px",
              cursor: "pointer",
              "&:hover": {
                backgroundColor: "#DBDD26",
              },
            }}
            onClick={() => onDelete()}
          >
            Delete
          </Button>
        </Box>
      ) : (
        <Button
          disableRipple
          sx={{
            width: "100%",
            background: "#DBDD26",
            color: "black",
            textTransform: "capitalize",
            padding: "8px",
            cursor: "pointer",
            borderRadius: "0px",
            "&:hover": {
              backgroundColor: "#DBDD26",
            },
          }}
          onClick={() => handleClose()}
        >
          Okay
        </Button>
      )}
    </Dialog>
  );
};

export default CustomDialogWithButtons;
